import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Basic } from 'app/forms/models/basic';
import { HttpService } from '../services/http.service';
import { LOV_TYPE } from 'app/shared/Constants';
import { ExcelService } from 'app/services/excel.service';
import { StoreService } from 'app/services/store.service';
import {FormsModule} from '@angular/forms';
import {MatExpansionModule} from '@angular/material/expansion';

@Component({
  selector: 'admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  fprList = [];
  fprLOI=false;
  mstUser;
  mstRole;
  lois=[];
  titleText;
  get actor(){
    return this.store.actor;
}

get omcs(){
    return this.store.omcs;
}
get districts(){
    return this.store.districts;
}

  constructor(private router: Router, 
    private httpService :HttpService,
    private excelService: ExcelService,
    private store: StoreService) { 
    {
        this.titleText="LOIs Assigned to FPRs";
        this.httpService.getFPR(this.store.omc).subscribe((resp) =>{
       //   console.log("*********Fetched FPRs are************");
       //   console.log(resp);
          resp=resp["data"];
          this.fprList = resp["items"];
       //   console.log(this.fprList);
      });
        }
  }

  ngOnInit(): void {
  }
  fetchFPR()
    {
        {
            this.httpService.getFPR(this.store.omc).subscribe((resp) =>{
            //    console.log("*********Fetched FPRs are************");
            //    console.log(resp);
                this.fprList =resp["items"];
            });
        }
    }

    goToFPRLOI(fpr)
    {
        this.fprLOI=true;
        this.mstUser = fpr.user_id;
        
     //   console.log("*******USERID AND USERROLE ARE*******");
     //   console.log(this.mstUser);

        this.store.userId=this.mstUser;
        //this.store.actor=2;
        
        this.router.navigate(['/home']);

         
    }
    goToReport(){
        this.router.navigate(['dashboard']);
    }
    
    exportData(){
      this.httpService.download(this.store.omc, this.store.actor).subscribe(resp => 
          {
              if(resp["status"]==1){
                  let res = resp["data"];
                  this.excelService.exportAsExcelFile(res["items"], 'LOIData');
              } else if(resp["status"]==1){
                  this.router.navigate(["/"]);
              }
          }
          
      );
  }

}
