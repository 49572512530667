import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Injectable({
    providedIn: 'root'
  })
  export class GlobalService {

    constructor(
        private snackBar: MatSnackBar,
        private http:HttpClient,
        private route:ActivatedRoute,
        private router: Router,
        public datepipe:DatePipe) { }


        openErrorPrompt(message: string, action: string) {
            this.snackBar.open(message, action, {
              duration: 5000,
            });
          }


  }