import { Component, OnInit } from '@angular/core';
import Chart from 'chart.js';
import { HttpService } from 'report/http.service';


@Component({
    selector: 'dashboard-cmp',
    moduleId: module.id,
    templateUrl: 'dashboard.component.html'
})

export class DashboardComponent implements OnInit{

  public canvas : any;
  public ctx;
  public chartq0;
  public chartq1;
  public chartq2;
  public chartq3;
  public chartq4;
  public chartq5;
  public chartq6;
  public chartq7;
  public chartq8;
  public chartq9;
  public chartq10;
  public chartq11;
  public chartColor;
  charts = {};

  config = [
    {"chartType" : "doughnut" , "axes" : false, "stacked" : false}, //1
    {"chartType" : "bar" , "axes": true, "stacked" : false }, //2
    {"chartType" : "line",  "axes": true, "stacked" : false }, //3
    {"chartType" : "bar" ,  "axes": true, "stacked" : false}, //4
    {"chartType" : "bar",  "axes": true, "stacked" : false }, //5
    {"chartType" : "bar",  "axes": true, "stacked" : true }, //6
    {"chartType" : "bar",  "axes": true, "stacked" : false }, //7
    {"chartType" : "bar",  "axes": true, "stacked" : false }, //8
    {"chartType" : "bar",  "axes": true, "stacked" : false }, //9
    {"chartType" : "bar",  "axes": true, "stacked" : false }, //10
    {"chartType" : "bar",  "axes": true, "stacked" : true } //11
  ]; 

 Color = {
 "IOCL" : "#ee7c36",
 "HPCL" : "#0f275a",
 "BPCL" : "#F7D706",
 "GAIL" : "#9c2630",
 "IGL" : "#007E3D"
}

urls = [
  "Satat_Report_NumberOfLoi",
  "Satat_Report_PlantComissioned",
  "Satat_Report_MonthWisePlantCommissioned",
  "Satat_Report_LandFinalization",
  "Satat_Report_FertilizerAgreement",
  "SATAT_REPORT_LOI_COMPLETION", //"Satat_Report_PlantProgress",
  "Satat_Report_StatutoryApprovals",
  "Satat_Report_FinancialClosure",
  "Satat_Report_StatutoryApprovals_Applied",
  "Satat_Report_StatutoryApprovals_Issue",
  "Satat_Report_Milestones"
];

total = [];

selectedOmc = "ALL";
selectedState = { state: "ALL", state_id: "ALL"};
selectedDistrict = "ALL";

states;
districts;
currentYear = new Date().getFullYear();
nextYear = this.currentYear + 1;


  constructor(private httpService : HttpService){

  }

  refreshCharts(){
    this.chartq1 = this.fetchResponse(1);
    this.chartq2 = this.fetchResponse(2);
    this.chartq3 = this.fetchResponse(3);
    this.chartq4 = this.fetchResponse(4);
    this.chartq5 = this.fetchResponse(5);
    this.chartq6 = this.fetchResponse(6);
    this.chartq7 = this.fetchResponse(7);
    this.chartq8 = this.fetchResponse(8);
    this.chartq9 = this.fetchResponse(9);
    this.chartq10 = this.fetchResponse(10);
    this.chartq11 = this.fetchResponse(11);
  }

  omcSelected(){
    this.refreshCharts();
  }

  stateSelected(){
  //  console.log(this.selectedState);
    if(this.selectedState.state == "ALL"){
      this.selectedDistrict = "ALL";
      this.districts = [];
    //  console.log("ALL");
    } else {
      this.httpService.fetchDistricts(this.selectedState.state_id).subscribe(res => {
        let resp = res["data"];
        this.districts = resp["items"].map(r => r.district);
      });
     
    }
    
    this.refreshCharts();
  }

  districtSelected(){
    this.refreshCharts();
  }

  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

fetchResponse(qno) {
  let chart;
  this.httpService.getChartData(this.urls[qno-1], this.selectedState.state, this.selectedDistrict, this.selectedOmc).subscribe(res => {
    let data = res["data"];
    let status = res["status"];
    if(status=="1"){
      let arr = data["items"];
      let legend = [];
      let values = [];
      let bgColors = [];
      let groupedData = new Map();
      switch(qno){
        case 1:
        case 4:
        case 8:
        case 5:
        case 2:{
          arr.forEach(element => {
          if(element.omc){
            values.push(element.valuecount);
            legend.push(element.omc);
            bgColors.push(this.Color[element.omc]);
          }
        });
        break;
      }
        //case 6:
        case 3:{
          let legendArr = ["Apr " + this.currentYear, "May " + this.currentYear, "Jun " + this.currentYear, "Jul " + this.currentYear, "Aug " + this.currentYear, "Sep "+ this.currentYear, "Oct "+ this.currentYear, "Nov "+ this.currentYear, "Dec "+ this.currentYear, "Jan "+ this.nextYear, "Feb "+ this.nextYear, "Mar "+ this.nextYear];
          legend = [["Apr",this.currentYear], ["May",this.currentYear], ["Jun",this.currentYear], ["Jul" , this.currentYear], ["Aug",this.currentYear], ["Sep", this.currentYear], ["Oct", this.currentYear], ["Nov", this.currentYear], ["Dec", this.currentYear], ["Jan", this.nextYear], ["Feb", this.nextYear], ["Mar", this.nextYear]];
          arr.forEach(element => {
           let indx = legendArr.indexOf(element.mon + " " + element.yr);
            if(element.omc){
              if(groupedData.get(element.omc)){
                let valueArr = groupedData.get(element.omc);
                valueArr[indx] = element.valuecount;
                groupedData.set(element.omc, valueArr);
              } else {
                let valueArr = [0,0,0,0,0,0,0,0,0,0,0,0];
                valueArr[indx] = element.valuecount;
                groupedData.set(element.omc, valueArr);
              }
            }
          });
         
          /*const grouped = this.groupBy(arr, a => a.mon);
          legend = [ ...grouped.keys() ].filter(x => x!=null);
          groupedData = this.groupBy(arr, a => a.omc);*/
          break;
        }
        /*case 6:{
          legend = ["Less than 20%", "20% to 40%", "40% to 60%", "60% to 80%", "Greater than 80%"];
          arr.forEach(element => {
            if(element.omc){
              let valueArr = [element.lessthan20, element.range20to40, element.range40to60, element.range60to80, element.greaterthan80];
              groupedData.set(element.omc, valueArr);
            }
          });
          break;
        }*/

        case 6:{
          let legendArr = ["lessthan20", "range20to40", "range40to60", "range60to80", "greaterthan80"];
          legend = ["< 20%", "20% - 40%", "40% - 60%", "60% - 80%", "> 80%"];
          arr.forEach(element => {
           let indx = legendArr.indexOf(element.range);
            if(element.omc){
              if(groupedData.get(element.omc)){
                let valueArr = groupedData.get(element.omc);
                valueArr[indx] = element.valuecount;
                groupedData.set(element.omc, valueArr);
              } else {
                let valueArr = [0,0,0,0,0];
                valueArr[indx] = element.valuecount;
                groupedData.set(element.omc, valueArr);
              }
            }
          });
         
        }
        break;
        
        case 9:
        case 10:
        case 7:{
          //legend = ["Environment Clearance", "PCB Consent to Establish", "PCB Consent to Operate", "PESO Approval"];
          legend = [["PCB Consent","to Establish"], ["PCB Consent","to Operate"], "PESO Approval"];
          arr.forEach(element => {
            if(element.omc){
              //let valueArr = [element.environment_clearance, element.pcb_consent_to_establish, element.pcb_consent_to_operate, element.peso_approval];
              let valueArr = [element.pcb_consent_to_establish, element.pcb_consent_to_operate, element.peso_approval];
              groupedData.set(element.omc, valueArr);
            }
          });
          break;
        }
        case 11:
          legend = ["BG Submitted", "Land Finalized", ["Financial","Closure"], ["Commercial","Agreement Signed"], ["Consent","to Establish"], "PESO Approval", ["Operation","Commenced"]];
          arr.forEach(element => {
            if(element.omc){
              let valueArr = [element.bg_submitted, element.land_finalized, element.financial_closure, element.ca_submitted, element.pcb_consent_to_establish ,element.peso_approval, element.operation_commenced];
              groupedData.set(element.omc, valueArr);
            }
          });
          break;
      }
      if(this.charts[qno-1]){
        chart = this.charts[qno-1];
  
        if(qno==3 || qno==6 || qno==7 || qno==9 || qno==10 || qno==11){
          let total = 0;
          groupedData.forEach(values => {
            total += values.reduce((sum, current) => sum + current, 0);
          });
          this.total[qno-1] = total;
          chart.data.datasets = [{
            backgroundColor: this.Color["IOCL"],
            borderColor: this.Color["IOCL"],
            borderWidth: 2,
            fill :false,
            pointRadius: 4,
            data: groupedData.get("IOCL")
          },
          {
            backgroundColor: this.Color["BPCL"],
            borderColor: this.Color["BPCL"],
            borderWidth: 2,
            fill :false,
            pointRadius: 4,
            data: groupedData.get("BPCL")
          },
          {
            backgroundColor: this.Color["HPCL"],
            borderColor: this.Color["HPCL"],
            borderWidth: 2,
            fill :false,
            pointRadius: 4,
            data: groupedData.get("HPCL")
          },
          {
            backgroundColor: this.Color["GAIL"],
            borderColor: this.Color["GAIL"],
            borderWidth: 2,
            fill :false,
            pointRadius: 4,
            data: groupedData.get("GAIL")
          },
          {
            backgroundColor: this.Color["IGL"],
            borderColor: this.Color["IGL"],
            borderWidth: 2,
            fill :false,
            pointRadius: 4,
            data: groupedData.get("IGL")
          }
        ]
        } else {
          this.total[qno-1] = values.reduce((sum, current) => sum + current, 0);
          chart.data.datasets.forEach((dataset) => {
          dataset.data = values;
          dataset.backgroundColor = bgColors;
        });
      }
  
      chart.data.labels = legend;
      chart.update();
  
    
      } else {
        let canvas: any = document.getElementById("chartq" + qno.toString());
        let ctx = canvas.getContext("2d");
      
        if(qno==3 || qno==6 || qno==7 || qno == 9 || qno == 10 || qno == 11){
          chart = this.prepareMultiChart(legend, ctx, qno, groupedData);
        } else chart =  this.prepareChart(legend, values, ctx, qno, bgColors);
        this.charts[qno-1] = chart;
      }
    }
    return chart;
  });
}

  prepareChart(legend, values, ctx, qno, bgColors){
    this.total[qno-1] = values.reduce((sum, current) => sum + current, 0);
    let chart = new Chart(ctx, {
      type: this.config[qno-1].chartType,
      total : this.total[qno-1],
      data: {
        labels: legend,
        datasets: [{
            backgroundColor: bgColors,
            borderColor: "rgb(255, 99, 132)",
            borderWidth: 0,
            fill :false,
            pointRadius: 3,
          //  pointHoverRadius: 0,

            data: values
            //barThickness : 'flex'
           // barPercentage: 0.7
          }
        ]
      },
      options: {
        cutoutPercentage : 50,
        legend: {
          display: false
        },

        tooltips: {
          enabled: true
        },
      
        scales: {
          yAxes: [{
            ticks: {
              display : this.config[qno-1].axes,
              //fontColor: "#9f9f9f",
              beginAtZero: true,
              maxTicksLimit: 10,
              
            },
            gridLines: {
              display : this.config[qno-1].axes
            }
          }],
         /*
          xAxes: [{
            
            gridLines: {
              drawBorder: false,
              color: 'rgba(255,255,255,0.1)',
              zeroLineColor: "transparent",
              display: false,
            },
            ticks: {
              padding: 20,
              fontColor: "#9f9f9f"
            }
          }]*/
        }
      }
  });
  return chart;
  }

  prepareMultiChart(legend, ctx, qno, groupedData: Map<string, Array<number>>){
    //{IOC: [], }
  let total = 0;
   groupedData.forEach(values => {
     total += values.reduce((sum, current) => sum + current, 0);
  });
  this.total[qno-1] = total;
    //this.total[qno-1] = groupedData.reduce((sum, current) => sum + current, 0);
    let chart = new Chart(ctx, {
      type: this.config[qno-1].chartType,
      data: {
        labels: legend,
        datasets: [{
            backgroundColor: this.Color["IOCL"],
            borderColor: this.Color["IOCL"],
            borderWidth: 2,
            fill :false,
            pointRadius: 4,
          //  pointHoverRadius: 0,
            
            data: groupedData.get("IOCL")
            //barThickness : 'flex'
           // barPercentage: 0.7
          },
          {
            backgroundColor: this.Color["BPCL"],
            borderColor: this.Color["BPCL"],
            borderWidth: 2,
            fill :false,
            pointRadius: 4,
          //  pointHoverRadius: 0,
            
            data: groupedData.get("BPCL")
            //barThickness : 'flex'
           // barPercentage: 0.7
          },
          {
            backgroundColor: this.Color["HPCL"],
            borderColor: this.Color["HPCL"],
            borderWidth: 2,
            fill :false,
            pointRadius: 4,
          //  pointHoverRadius: 0,
            
            data: groupedData.get("HPCL")
            //barThickness : 'flex'
           // barPercentage: 0.7
          },
          {
            backgroundColor: this.Color["GAIL"],
            borderColor: this.Color["GAIL"],
            borderWidth: 2,
            fill :false,
            pointRadius: 4,
          //  pointHoverRadius: 0,
            
            data: groupedData.get("GAIL")
            //barThickness : 'flex'
           // barPercentage: 0.7
          },
          {
            backgroundColor: this.Color["IGL"],
            borderColor: this.Color["IGL"],
            borderWidth: 2,
            fill :false,
            pointRadius: 4,
          //  pointHoverRadius: 0,
            
            data: groupedData.get("IGL")
            //barThickness : 'flex'
           // barPercentage: 0.7
          }
        ]
      },
      options: {
        cutoutPercentage : 50,
        legend: {
          display: false
        },

        tooltips: {
          enabled: true
        },

        scales: {
          yAxes: [{
            stacked: this.config[qno-1].stacked,
            ticks: {
              display : this.config[qno-1].axes,
              //fontColor: "#9f9f9f",
              beginAtZero: true,
              maxTicksLimit: 10,
              
            },
            gridLines: {
              display : this.config[qno-1].axes
            }
          }],
          xAxes:[{
            stacked: this.config[qno-1].stacked,
            ticks: {
              display : this.config[qno-1].axes,
              //fontColor: "#9f9f9f",
              beginAtZero: true,
              maxTicksLimit: 10,
              
            },
            gridLines: {
              display : this.config[qno-1].axes
            }
          }]
         /*
          xAxes: [{
            
            gridLines: {
              drawBorder: false,
              color: 'rgba(255,255,255,0.1)',
              zeroLineColor: "transparent",
              display: false,
            },
            ticks: {
              padding: 20,
              fontColor: "#9f9f9f"
            }
          }]*/
        }

      }
  });
  return chart;
  }
  

    ngOnInit(){

      if(!this.states){
        this.httpService.fetchStates().subscribe(res => {
          let resp = res["data"];
          this.states = resp["items"];
          this.states.unshift({ state_id: "ALL", state: "ALL"});
          this.selectedState = this.states[0];
        });
      }
      
      this.refreshCharts();
      
    }
}
