export class LandFinalization {
    public loiId: string;
    public landFinalised: string;
    public reasonOfNoFinalization: string;
    public specificIssue: string;
    public issueDetails: string;
    public submit: number;
    public actor: number;

    constructor(jsonObj=null){
        if(jsonObj){
            this.loiId=jsonObj['loi_system_id'];
            this.landFinalised=jsonObj['status_land_finalization'];
            this.reasonOfNoFinalization=jsonObj['not_finalized_reason'];
            this.specificIssue=jsonObj['issue_from_autority'];
            this.issueDetails=jsonObj['issue_reason'];
            this.submit=jsonObj['form_status'];
            this.actor=jsonObj['record_role'];
        } else this.submit = 0;
        
    }
}