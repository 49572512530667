import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { FormMainComponent } from './forms/form-main.component';
import { UserHomeComponent } from './user-home/user.component';
import { HomeComponent } from './home/home.component';
import { AdminComponent } from './admin/admin.component';
import { GrievancesComponent } from './grievances/grievances.component';
import { GrievanceListComponent } from './grievances/grievance-list/grievance-list.component';
import { GrievanceSidebarComponent } from './grievances/grievance-sidebar/grievance-sidebar.component';
import { GrievancesMainComponent } from './grievances/grievances-main/grievances-main.component';
import { GrievanceSubmitResponseComponent } from './grievances/grievance-submit-response/grievance-submit-response.component';
import { MainpageComponent } from './mainpage/mainpage.component';
import {PagesCbgComponent} from './pages-cbg/pages-cbg.component';
import {PagesAboutComponent} from './pages-about/pages-about.component'
import { PagesSchemeComponent } from './pages-scheme/pages-scheme.component';
import { PagesFertilizersComponent } from './pages-fertilizers/pages-fertilizers.component';
import { ModulesComponent } from './modules/modules.component';
import { TechnologyComponent } from './technology/technology.component';
import { PagesComissionedPlantsComponent } from './pages-comissioned-plants/pages-comissioned-plants.component';
import { PagesParticipationComponent } from './pages-participation/pages-participation.component';
import { PagesSatatProcessComponent } from './pages-satat-process/pages-satat-process.component';
import { FaqComponent } from './faq/faq.component';
import { HighlightsComponent } from './highlights/highlights.component';
import { UploadPlantsComponent } from './upload-plants/upload-plants.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NewhomeComponent } from './newhome/newhome.component';

export const AppRoutes: Routes = [
  
  {
    path: '',
    component: MainpageComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'home',
    component: UserHomeComponent,
  },
  {
    path: 'newhome',
    component: NewhomeComponent,
  }, 
  {
    path: 'form',
    component: FormMainComponent
  },
  {
    path: 'admin',
    component: AdminComponent
  },
  {
    path: 'grievances-main',
    component: GrievancesMainComponent
  },
  {
    path: 'grievances',
    component: GrievancesComponent
  },
  {
    path: 'grievances-list',
    component: GrievanceListComponent
  },
  {
    path: 'grievances-sidebar',
    component: GrievanceSidebarComponent
  },
  {
    path: 'grievances-list/grievance-submit-response/:id',
    component: GrievanceSubmitResponseComponent
  },
  {
    path: 'login-page',
    component: HomeComponent
  },
  {
    path: 'main-page',
    component: MainpageComponent
  },
  {
    path:'cbg',
    component: PagesCbgComponent
  },

  {
    path:'about',
    component: PagesAboutComponent
  },

  {
    path:'scheme',
    component: PagesSchemeComponent
  },

  {
    path:'fertilizers',
    component: PagesFertilizersComponent
  },
  
  {
    path:'technology',
    component: TechnologyComponent

  },
  {
    path:'module',
    component: ModulesComponent
  },
  {
    path:'comissionedPlants/:id',
    component: PagesComissionedPlantsComponent
  },
 {
    path: 'participation',
    component: PagesParticipationComponent
 },
 {
    path:'processAtGlance',
    component: PagesSatatProcessComponent
 },

 {
    path:'faq',
    component: FaqComponent
 }, 

 {
  path:'highlights',
  component: HighlightsComponent
},

{
  path:'uploadPlants',
  component: UploadPlantsComponent
},

{
  path:'resetPassword',
  component: ResetPasswordComponent
} 
  
/*,
  {
    path: 'report',
    redirectTo: '/report',
    pathMatch: 'full'
  }*/
]