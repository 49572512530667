import { Component, OnInit ,OnDestroy,AfterViewInit,ElementRef, HostListener, Renderer2,ViewChild  } from '@angular/core';
import { Router,NavigationStart, NavigationEnd } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from '../services/http.service';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { interval,Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

 
declare var jQuery: any;

@Component({
  selector: 'app-developments',
  templateUrl: './developments.component.html',
  styleUrls: ['./developments.component.css'],
})

export class DevelopmentsComponent implements OnInit {

plants;
//  private isDown = false;
//  private scrollX: number | undefined;
//  private scrollLeft: number | undefined;
 private autoScrollSubscription: any;
 autoScrollInterval: any;

 @ViewChild('cardsContainer') cardsContainer!: ElementRef;
 @ViewChild('scrollContainer') scrollContainer!: ElementRef;


  constructor(private router: Router, private _Activatedroute:ActivatedRoute, private httpService :HttpService,  private sanitizer: DomSanitizer,private el: ElementRef, private renderer: Renderer2,) {

    
   }
   name = 'Angular';
   imageObject = [{
       image: 'assets/images/plants-1.jpg',
       thumbImage: 'assets/images/plants-1.jpg',
       title: 'Noble Exchange Environment Solutions, Pune'
   }, 
   {
       image: 'assets/images/plants-2.png',
       thumbImage: 'assets/images/plants-2.png',
       title: 'Clarus Bioenergy Pvt. Ltd.,  Shirala'
   },
    {
       image: 'assets/images/plants-3.png',
       thumbImage: 'assets/images/plants-3.png',
       title: 'Spectrum Renewable Energy Pvt. Ltd., Kolhapur'
   },
   {
       image: 'assets/images/plants-4.png',
       thumbImage: 'assets/images/plants-4.png',
       title: 'Glow Green Biotech, Surat'
   }, {
       image: 'assets/images/plants-5.png',
       thumbImage: 'assets/images/plants-5.png',
       title: 'Spectrum Renewable Energy <br> Pvt. Ltd., Rohtak'
   }, 
   {
       image: 'assets/images/plants-6.png',
       thumbImage: 'assets/images/plants-6.png',
       title: 'IOT Biogas, Namakkal'
   },
   {
       image: 'assets/images/plants-7.png',
       thumbImage: 'assets/images/plants-7.png',
       title: 'Bleach Energy,Anand'
   },

   {
    image: 'assets/images/plants-8.png',
    thumbImage: 'assets/images/plants-8.png',
    title: 'Green Earth Biogas Pvt Ltd, Surendranagar'
    },

    {
      image: 'assets/images/plants-9.png',
      thumbImage: 'assets/images/plants-9.png',
      title: 'Solika Energy Pvt. Ltd, Shadnagar'
    },

    {
      image: 'assets/images/plants-10.png',
      thumbImage: 'assets/images/plants-10.png',
      title: 'Sri Lakshmi Venkateshwara Green Crops Power Projects, Kadapa'
    },

    {
      image: 'assets/images/plants-11.png',
      thumbImage: 'assets/images/plants-11.png',
      title: 'T R Mega Food and Beverages LLP, Ludhiana'
    },

    {
      image: 'assets/images/plants-12.jpg',
      thumbImage: 'assets/images/plants-12.jpg',
      title: 'Indian Potash Limited, Muzzafarnagar'
    },

    {
      image: 'assets/images/plants-13.jpg',
      thumbImage: 'assets/images/plants-13.jpg',
      title: 'Vyzag Bio-Energy Fuel Pvt. Ltd, Visakhapatnam'
    },

    {
      image: 'assets/images/plants-14.jpg',
      thumbImage: 'assets/images/plants-14.jpg',
      title: 'SLR Energy, Cuddalore, Tamil Nadu'
    },

    {
      image: 'assets/images/plants-15.jpg',
      thumbImage: 'assets/images/plants-15.jpg',
      title: 'Shri Govardhannathji Energies LLP, Gujarat'
    },

    {
      image: 'assets/images/plants-16.jpg',
      thumbImage: 'assets/images/plants-16.jpg',
      title: 'Mahindra Waste to Energy Solutions Limited, Indore'
    },
    {
      image: 'assets/images/plants-17.jpg',
      thumbImage: 'assets/images/plants-17.jpg',
      title: 'IL&FS Environmental Infrastructure & Services Limited, Indore'
    },
    {
      image: 'assets/images/plants-18.jpg',
      thumbImage: 'assets/images/plants-18.jpg',
      title: 'Hyderabad Integrated MSW Limited, Hyderabad, Telangana'
      },

      {
        image: 'assets/images/plants-19.jpg',
        thumbImage: 'assets/images/plants-19.jpg',
        title: 'Anarobic Energy Private Limited, Haridwar, Uttarakhand'
      },
      {
        image: 'assets/images/plants-20.jpg',
        thumbImage: 'assets/images/plants-20.jpg',
        title: 'Leafiniti Bioenergy Private Limited, Karnataka'
      },
      {
        image: 'assets/images/plants-21.jpg',
        thumbImage: 'assets/images/plants-21.jpg',
        title: 'Energim Sustainable Solutions Cuddalore Pvt. Ltd, Tamil Nadu'
      },
      {
        image: 'assets/images/plants-22.jpeg',
        thumbImage: 'assets/images/plants-22.jpeg',
        title: 'Gobardhan Varanasi Foundation - SPV'
      },
      {
        image: 'assets/images/plants-23.jpeg',
        thumbImage: 'assets/images/plants-23.jpeg',
        title: 'Sayaji Bio Energies LLP'
      },
      {
        image: 'assets/images/plants-24.jpg',
        thumbImage: 'assets/images/plants-24.jpg',
        title: 'Solika Energy Pvt. Ltd.'
      },
      {
        image: 'assets/images/plants-25.jpg',
        thumbImage: 'assets/images/plants-25.jpg',
        title: 'Verbio India Pvt. Ltd.'
      },
      {
        image: 'assets/images/plants-26.jpg',
        thumbImage: 'assets/images/plants-26.jpg',
        title: 'Akshar Biotech Private Limited'
      },
  ];

  

  routeTo(event){
    if(event>=0)
      this.router.navigate(['/comissionedPlants']);
  }


  ngOnInit(): void {
    // this.autoScrollSubscription = interval(3000).subscribe(() => {
    //   this.scrollImages();
    // });
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe(() => {
      this.stopAutoScroll();
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.startAutoScroll();
    });

// To stop scrolling when images are not visible on page
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Adjust the threshold as needed
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.startAutoScroll();
        } else {
          this.stopAutoScroll();
        }
      });
    }, options);

    // Observe the scroll container
    const scrollContainer = this.scroll;
    if (scrollContainer) {
      observer.observe(scrollContainer);
    }

    this.startAutoScroll();
  }


  ngOnDestroy(): void {
    if (this.autoScrollSubscription) {
      this.autoScrollSubscription.unsubscribe();
    }
    this.stopAutoScroll();

    if (this.autoScrollInterval) {
      clearInterval(this.autoScrollInterval);
    }
  }

  private startAutoScroll(): void {
    if (!this.autoScrollSubscription) {
      // Start auto-scrolling after 5 seconds and repeat every 5 seconds
      this.autoScrollSubscription = interval(3000).subscribe(() => {
        this.scrollImages();
      });
    }
  }

  private stopAutoScroll(): void {
    if (this.autoScrollSubscription) {
      // Unsubscribe from the auto-scrolling interval
      this.autoScrollSubscription.unsubscribe();
      this.autoScrollSubscription = undefined;
    }
  }

  scrollImages(): void {
    const cards = this.el.nativeElement.querySelectorAll('.card');
    const scrollContainer = this.scroll;

    if (cards && cards.length > 0 && scrollContainer) {
      const cardWidth = cards[0].offsetWidth;
      const scrollAmount = cardWidth + 15;

        // Check if reaching the end of the scroll container
    if (scrollContainer.scrollLeft + scrollContainer.clientWidth >= scrollContainer.scrollWidth) {
      // Reset scroll position to the beginning
      scrollContainer.scrollLeft = 0;
    } else {
      // Scroll by the specified amount
      scrollContainer.scrollLeft += scrollAmount;
    }
    }
  }


  ngAfterViewInit() {

    this.startAutoScroll();
    // (function ($) {
    //   $(document).ready(function () {
    //     $('.owl-carousel').owlCarousel({
    //       items:4, 
         
          
    //         loop:true,
    //         margin:10,
    //         autoplay:true,
    //         autoplayTimeout:3000,
    //         autoplayHoverPause:true,
    //         dots:true,
            
    //     });
    //   });
    // })(jQuery);

    // (function ($) {
    //   "use strict";
    
    //   var carousels = function () {
    //     $(".owl-carousel1").owlCarousel({
    //       loop: true,
    //       center: true,
    //       margin: 0,
    //       responsiveClass: true,
    //       nav: false,
    //       responsive: {
    //         0: {
    //           items: 1,
    //           nav: false
    //         },
    //         680: {
    //           items: 2,
    //           nav: false,
    //           loop: false
    //         },
    //         1000: {
    //           items: 3,
    //           nav: true
    //         }
    //       }
    //     });
    //   };
    
    //   (function ($) {
    //     carousels();
    //   })(jQuery);
    // })();


    this.httpService.getCommissionedPlants().subscribe(resp => {     
      if(resp["status"]==1){
          let res = resp["data"];
          this.plants = res["items"];
          console.log("this.plants",this.plants);
       }
      
    });
  }

  get scroll(): HTMLElement | null {
    return this.el.nativeElement.querySelector('.scroll');
  }
}
