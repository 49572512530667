import { Component, OnInit } from '@angular/core';
import {formatDate} from '@angular/common';
import { HttpService } from 'app/services/http.service';
import { StoreService } from 'app/services/store.service';


@Component({
  selector: 'grievance-list',
  templateUrl: './grievance-list.component.html',
  styleUrls: ['./grievance-list.component.css']
})
export class GrievanceListComponent implements OnInit {

  tableFields;
  grievanceList;
  reportKeys;
  toDate = "01-01-2900";
  displaytoDate;
  fromDate = "01-01-1900";
  displayfromDate;
  selectedGrievanceStatus;
  getGrievanceListStatus = -1;
  errorMsg="An error occured in fetching the data..";

  get actor(){
    return this.store.actor;
  }

  constructor(private httpService : HttpService,private store: StoreService) { }

  ngOnInit(): void {
    this.tableFields = ["complaint_id", "complaint_date", "created_by", "comp_category", "comp_sub_category", "status"];
    if(this.store.actor == 1){
       this.selectedGrievanceStatus = "PF";
    }
    else if(this.store.actor == 2 || this.store.actor == 5 || this.store.actor == 3){ 
       this.selectedGrievanceStatus = "PR";
    }
    this.filterChanged();
  }

  filterChanged(){

    //console.log("called filterChanged :   ");
    
    //this.tableFields = ["Complaint No", "Status", "Created Date", "LOI Id", "Created By", "Details"];
    let body = {
       user_id: this.store.userId,
       user_role : this.store.actor,
       list_type: this.selectedGrievanceStatus
    }
     this.httpService.getGrievanceList("get-grievance-list",body).subscribe(res => {
       let resp = res["data"];
       let status = res["status"];
       if(status=="1"){ 
          this.grievanceList = resp["complaints"];;
        //if(this.reportKeys)         
          if(!this.grievanceList[0]){
            this.getGrievanceListStatus=0;
            this.errorMsg = "There are no any existing complaints with this filter";
          }else{
          this.reportKeys = Object.keys(this.grievanceList[0]);
          this.getGrievanceListStatus=1;
          }
       }   
    },
    (err) => this.getGrievanceListStatus=0
    );

   
  }

}
