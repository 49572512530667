import { Component, OnInit } from '@angular/core';
import { HttpService } from '../services/http.service';
import { Router } from '@angular/router';
import { Basic } from 'app/forms/models/basic';
import { ExcelService } from 'app/services/excel.service';
import { StoreService } from 'app/services/store.service';
import {MatDialog, MatDialogRef, MatDialogModule, MatDialogConfig} from '@angular/material/dialog';

@Component({
  selector: 'app-update-vendor-name',
  templateUrl: './update-vendor-name.component.html',
  styleUrls: ['./update-vendor-name.component.css']
})
export class UpdateVendorNameComponent implements OnInit {
  vendors = null;
  selectedVendor;
  nameToChange;

  constructor(private router: Router, 
    private httpService :HttpService,
    private excelService: ExcelService,
    public dialog: MatDialog,
    private store: StoreService,
    private dialogRef: MatDialogRef<UpdateVendorNameComponent>,) { }

  ngOnInit(): void {
    if(!this.vendors){
      this.httpService.getVendors().subscribe(resp => {
          if(resp["status"]==1){
              let res = resp["data"];
              this.vendors = res["items"];
          }
      });
  }
  }

  sortVendors(criteria: string) {
    if (criteria === 'nameAsc') {
      this.vendors.sort((a, b) => a.display_name.localeCompare(b.display_name));
    } else if (criteria === 'nameDesc') {
      this.vendors.sort((a, b) => b.display_name.localeCompare(a.display_name));
    }
  }

  submit(){
    console.log("vendor is: ",this.selectedVendor.display_name);

    this.httpService.updateVendor(this.nameToChange,this.selectedVendor.display_name).subscribe(resp => 
      {
          if(resp["status"]==1){
              let res = resp["data"];
             
          } else if(resp["status"]==1){
              this.router.navigate(["/"]);
          }
          console.log("vendor is: ",this.selectedVendor.display_name);
      }
    );
    this.httpService.updateVendorMSTUSER(this.nameToChange,this.selectedVendor.display_name).subscribe(resp => 
      {
          if(resp["status"]==1){
              let res = resp["data"];
             
          } else if(resp["status"]==1){
              this.router.navigate(["/"]);
          }
          console.log("MST USER vendor is: ",this.selectedVendor.display_name);
      }
    );
    this.dialogRef.close();
  }

  close(){
    this.dialogRef.close();
  }
}
