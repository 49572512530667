import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GrievanceSidebarComponent } from './grievance-sidebar.component';



@NgModule({
  declarations: [GrievanceSidebarComponent],
  imports: [
    RouterModule, CommonModule
  ],
  exports: [ GrievanceSidebarComponent ]
})

export class GrievanceSidebarModule { }
