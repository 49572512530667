import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { HttpService } from 'app/services/http.service';
import { StoreService } from 'app/services/store.service';

@Component({
  selector: 'app-grievance-submit-response',
  templateUrl: './grievance-submit-response.component.html',
  styleUrls: ['./grievance-submit-response.component.css']
})
export class GrievanceSubmitResponseComponent implements OnInit {
  activatedRoute: any;
  //responseHistoryTableFields;
  responseHistoryHeaders;
  compId;
  complaintHeader;
  complaintResponse;
  complaintFeedback;
  complaintRespHistory;
  responseText;
  closeBtnText = "Close complaint"; showCloseBtn = false;
  reOpenBtnText = "Re-open complaint"; showReOpenBtn = false;
  resolveBtnText = "Resolve complaint"; showResolveBtn = false;
  responseSubmitStatus=-1;
  successMsg = "Resolution submitted successfully!";
  errorMsg = "An error occurred. Please try again later";
  eligibleResolution = false;
  eligibleFeedback = false;

  constructor(private route:ActivatedRoute, private httpService : HttpService,private store: StoreService) { 
      this.route.params.subscribe(params => {   
         this.compId =  params['id'];
    });}

  

  ngOnInit(): void {
    //this.responseHistoryTableFields = ["seq_no", "status", "action_by_role", "action_by_user_id", "action_on", "response_text"];  
    this.getCompaintDetails();  
  }

  getCompaintDetails() {
    let body = {
      complaint_id: this.compId
    }
    this.httpService.getGrievanceDetails("get-grievance-detail",body).subscribe(res => {
      let resp = res["data"];
      let status = res["status"];
      //console.log(res);
      if(status=="1"){ 
          this.complaintHeader = resp["comp_header"][0];
          this.complaintRespHistory = resp["comp_detail"];
          this.responseHistoryHeaders = Object.keys(this.complaintRespHistory[0]);
          if(this.store.actor==1 && this.complaintHeader.status=="R"){
            this.eligibleFeedback = true;
            this.showCloseBtn = true;
            this.showReOpenBtn = true;
          }else if( (this.store.actor==2 || this.store.actor==5 || this.store.actor==3) && (this.complaintHeader.status=="N" || this.complaintHeader.status=="O")){
            this.eligibleResolution = true;
            this.showResolveBtn = true;
          }else{
            this.eligibleFeedback = false;
            this.eligibleResolution = false;
          }
      }   
   });
  }

  onSubmitResponse(action){
    
    if(this.store.actor==1 && this.complaintHeader.status=="R")       
      this.responseText= this.complaintFeedback;
    else if( (this.store.actor==2||this.store.actor==5||this.store.actor==3) && (this.complaintHeader.status=="N"||this.complaintHeader.status=="O"))
      this.responseText= this.complaintResponse; 
    let body = {
      COMPLAINT_ID: this.compId,
      LOI_REF_NO: this.complaintHeader.loi_reference_number,
      RESPONSE_TEXT: this.responseText,   
      USER_ROLE: this.store.actor,
      USER_ID: this.store.userId,
      ACTION_STATUS: action
    }
    this.httpService.saveGrievanceResponse("save-grievance-response",body).subscribe(resp => {
      if(resp["status"]==1){
        let res = resp["data"];
        this.responseSubmitStatus = res["STATUS"];
        this.successMsg = res["OUTPUT_MSG"];
        this.errorMsg = res["OUTPUT_MSG"];
        this.getCompaintDetails();
      }
   },
   (err) => this.responseSubmitStatus = 0
   );
  }



}
