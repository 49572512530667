import { Component, OnInit } from '@angular/core';
import { HttpService } from '../services/http.service';
import { GlobalService } from 'app/services/globalService';
import { timer } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  usernameout = JSON.parse(sessionStorage.getItem('usernameout').toString());
  loginPassword = JSON.parse(sessionStorage.getItem('password').toString());

  public newPassword;
  public confirmNewPassword;

  public isMatching = true;
  public showPassword: boolean = false;
  
  public passwordStrength = {
    length: false,
    numbers: false,
    specialCharacters: false,
    uppercase: false
  };

  constructor(private router: Router, private httpService : HttpService, public gdata:GlobalService, private dialogRef: MatDialogRef<ForgotPasswordComponent>,) { }

  ngOnInit(): void {
  }

  checkPasswordStrength(password: string): void {
    this.passwordStrength.length = password.length >= 8;
    this.passwordStrength.numbers = /\d/.test(password);
    this.passwordStrength.specialCharacters = /[@%+\\/!$&?[\]]/.test(password);
    this.passwordStrength.uppercase = /[A-Z]/.test(password);
  }

  passwordStrengthCheck(): boolean {
    return Object.keys(this.passwordStrength).every(key => this.passwordStrength[key]);
  }


  // public togglePasswordVisibility(): void {
  //   this.showPassword = !this.showPassword;
  // }

  submit(){
    console.log("login password is ", this.loginPassword)

    // if(this.oldPassword == this.loginPassword ){
    //   this.isValid = true;
    // } else if (this.oldPassword != this.loginPassword)
    // {
    //   this.isValid = false;
    //   this.gdata.openErrorPrompt("Old Password is not matching","OK");
    // }
    if(this.newPassword == this.confirmNewPassword && this.passwordStrengthCheck() ){
      this.isMatching = true;
    } else if (this.newPassword != this.confirmNewPassword)
    {
      this.isMatching = false;
      this.gdata.openErrorPrompt("Password is not matching","OK");
    }
    console.log("both pwd matching ", this.isMatching)

    if(this.isMatching){
      this.httpService.createPassword(this.usernameout, this.newPassword).subscribe(
        res=> {
          let resp = res["data"];
          console.log("yor desired data is ", resp)
          if(resp["status"]==1){
            this.gdata.openErrorPrompt("Password successfully changed","OK"); 
            this.dialogRef.close();
            // timer(1500).subscribe(() => {          
            //   this.dialogRef.close();
            // });
            this.router.navigate(['/main-page']);
          }
        }
      );
    }
  }

  close(){
    this.dialogRef.close();
  }

}
