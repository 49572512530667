import { Component, OnInit } from '@angular/core';
import { HttpService } from '../services/http.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-upload-plants',
  templateUrl: './upload-plants.component.html',
  styleUrls: ['./upload-plants.component.css']
})
export class UploadPlantsComponent implements OnInit {

plants =[];
plants_filter =[];
plants_filter_without_img =[];
id:string;
searchplants:string;

  constructor(
    private httpService :HttpService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.httpService.getCommissionedPlants().subscribe(resp => {     
      if(resp["status"]==1){
          let res = resp["data"];
          this.plants = res["items"];
          this.plants_filter = res["items"];
          var data =res["items"];
          for(var i=0;i<data.length; i++){
            this.plants_filter_without_img.push({"capacity":data[i].capacity, "feedstock":data[i].feedstock, "location":data[i].location, "loi_issue":data[i].loi_issue, "plant_name":data[i].plant_name})
          }

    // this.id = this.route.snapshot.paramMap.get('id').toString();
    // console.log("id",this.id);
    // var elements = document.getElementById(this.id);
       }
    });
  }

  filterplants(){
    if(this.searchplants == null){
      this.plants_filter = this.plants;
      return;
    }

    var tempPlants =  this.plants_filter_without_img.filter((item: any) =>
    JSON.stringify(item).toLowerCase().indexOf(this.searchplants.trim().toLowerCase()) !== -1 );

           this.plants_filter =[];

           for(var j =0 ; j < tempPlants.length; j++){
            for(var k=0; k<this.plants.length;k++){
              if(tempPlants[j].plant_name == this.plants[k].plant_name){
                this.plants_filter.push(this.plants[k]);
              }
            }
          }
   }

  // routeTo(event){
  //   if(event>=0)
  //     this.router.navigate(['/uploadPlants']);
  // }
}