import { Component, OnInit, Renderer2, AfterViewInit,Directive, HostListener, ElementRef } from '@angular/core';
import { HttpService } from '../services/http.service';
import { Router } from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeUrl  } from '@angular/platform-browser';

declare var jQuery: any;

@Component({
  selector: 'app-newhome',
  templateUrl: './newhome.component.html',
  styleUrls: ['./newhome.component.css'],
  providers: [NgbCarouselConfig]
})


export class NewhomeComponent implements OnInit {
  stats: any = [];
  title = 'ng-carousel-demo';
  isAnimationPaused = false;
  notice;
  // headlines: { text: string, pdfUrl: string }[] = [];
  // headlines;
headlines: any[] = [];

  // headlines= [
  //  { text: 'Notice for inviting applications from CBG producers for seeking financial assistance to procure biomass aggregation machinery', pdfUrl: 'assets/download/Notice important.pdf'},
  // //  { text: 'seeking financial assistance to procure biomass aggregation machinery', pdfUrl: 'assets/download/Notice important.pdf'},
  // //  { text: 'Notice3', pdfUrl: 'assets/download/Notice important.pdf'},
  // //  { text: 'Notice4', pdfUrl: 'assets/download/Notice important.pdf'},
  // ];


  constructor(private httpService: HttpService, private router: Router,private renderer: Renderer2,private el: ElementRef,private sanitizer: DomSanitizer,
  ) {

    this.httpService.mainStatistics().subscribe((res) => {
      if (res["status"] == 1) {
        let resp = res["data"];
        //console.log('resp items')

        this.stats = resp["items"];
        // console.log(this.stats)
        this.setItem();
      }
    });


  }

  name = 'Angular';
  imageObject = [{
    image: 'assets/images/img-1.jpg',
    thumbImage: 'assets/images/img-1.jpg',
    title: 'Why Satat?'
  },
  {
    image: 'assets/images/img-2.jpg',
    thumbImage: 'assets/images/img-2.jpg',
    title: 'Lets Know CBG'
  },
  {
    image: 'assets/images/img-3.jpg',
    thumbImage: 'assets/images/img-3.jpg',
    title: 'Enablers of Scheme'
  }, {
    image: 'assets/images/img-4.jpg',
    thumbImage: 'assets/images/img-4.jpg',
    title: 'Organic Fertilizers'
  }, {
    image: 'assets/images/img-5.jpg',
    thumbImage: 'assets/images/img-5.jpg',
    title: 'Technology'
  }, {
    image: 'assets/images/img-6.jpg',
    thumbImage: 'assets/images/img-6.jpg',
    title: 'Learning Module'
  }];

  routeTo(event) {
    if (event == 0)
      this.router.navigate(['/about']);
    else if (event == 1)
      this.router.navigate(['/cbg']);
    else if (event == 2)
      this.router.navigate(['/scheme']);
    else if (event == 3)
      this.router.navigate(['/fertilizers']);
    else if (event == 4)
      this.router.navigate(['/technology']);
    else if (event == 5)
      this.router.navigate(['/module']);
  }

  ngOnInit(): void {
    // this.setupVideoClickEvent();
    this.httpService.getNoticeData().subscribe(resp => {     
      if(resp["status"]==1){
          let res = resp["data"];
          this.headlines = res["items"];
          console.log("notices in the table are",this.headlines);
       }
    });

    // this.httpService.getNoticeData().subscribe((data: any) => {     
    //   this.headlines = data.map(item => {
    //     return {
    //       text: item.NOTICE_CONTENT,
    //       pdfUrl: 'data:application/pdf;base64,' + item.FILE_CONTENT // Assuming FILE_CONTENT is base64 encoded
    //     };
    //   });
    //   console.log("notices in the table are",this.headlines);
    // });

  }

    // private setupVideoClickEvent(): void {
  //   const videoContainer = this.renderer.selectRootElement('.video-bg');
  //   const playIcon = this.renderer.selectRootElement('.play-icon', videoContainer);
  //   const videoSrc = videoContainer.getAttribute('data-src');

  //   if (playIcon && videoSrc) {
  //     this.renderer.listen(playIcon, 'click', () => {
  //       const iframe = this.renderer.createElement('iframe');
  //       this.renderer.setAttribute(iframe, 'src', videoSrc);
  //       this.renderer.setAttribute(iframe, 'allowfullscreen', 'true');
  //       this.renderer.setAttribute(iframe, 'frameborder', '0');

  //       this.renderer.removeChild(videoContainer, playIcon);
  //       this.renderer.appendChild(videoContainer, iframe);
  //     });
  //   }
  // }

  setItem() {
    sessionStorage.setItem('stats', JSON.stringify(this.stats));
    // console.log(JSON.parse(sessionStorage.getItem('stats')));
  }

  ngAfterViewInit() {
    (function ($) {
      $(document).ready(function () {
        $('.owl-carousel').owlCarousel({
          nav: true,
          navText: ["<i class='lni-chevron-left'></i>", "<i class='lni-chevron-right'></i>"],
          items: 3,

          loop: true,
          center: true,
          margin: 15,
          lazyLoad: false,
          dots: false,
          autoplay: true
        });
      });
    })(jQuery);
  }

  // pauseAnimation(): void {
  //   const elements = document.querySelectorAll('.notice-content ul li');
  //   elements.forEach((el: HTMLElement) => {
  //     el.style.animationPlayState = 'paused';
  //   });
  // }

  // resumeAnimation(): void {
  //   const elements = document.querySelectorAll('.notice-content ul li');
  //   elements.forEach((el: HTMLElement) => {
  //     el.style.animationPlayState = 'running';
  //   });
  // }

  // getMarqueeText(): string {
  //   return this.headlines.map(headline => headline.notice_content).join(' - ');
  // }

  // getMarqueePdfUrl(): string {
  //   return this.headlines.length > 0 ? this.headlines[0].file_content : '';
  // }


  // transformBase64ToSafeUrl(base64String: string): SafeUrl {
  //   const byteCharacters = atob(base64String.replace(/^data:.+;base64,/, ''));
  //   const byteNumbers = new Array(byteCharacters.length);
  //   for (let i = 0; i < byteCharacters.length; i++) {
  //     byteNumbers[i] = byteCharacters.charCodeAt(i);
  //   }
  //   const byteArray = new Uint8Array(byteNumbers);
  //   const blob = new Blob([byteArray], {type: 'application/pdf'});
  //   const blobUrl = URL.createObjectURL(blob);
  //   return this.sanitizer.bypassSecurityTrustUrl(blobUrl);
  // }

  // createFileLink(fileBase64: string): SafeUrl {
  //   const mimeType = this.extractMimeType(fileBase64);
  //   const blob = this.base64ToBlob(fileBase64, mimeType);
  //   const blobUrl = URL.createObjectURL(blob);
  //   return this.sanitizer.bypassSecurityTrustUrl(blobUrl);
  // }
  
  // // Helper method to convert a base64-encoded string to a Blob
  // base64ToBlob(base64: string, contentType: string) {
  //   const base64Data = base64.split(';base64,').pop();
  //   const binaryString = window.atob(base64Data);
  //   const bytes = new Uint8Array(binaryString.length);
  //   for (let i = 0; i < binaryString.length; i++) {
  //     bytes[i] = binaryString.charCodeAt(i);
  //   }
  //   return new Blob([bytes], { type: contentType });
  // }
  
  // // Extracts the MIME type from a base64 encoded string
  // extractMimeType(base64: string): string {
  //   const mimeTypeMatch = base64.match(/data:([^;]+);base64,/);
  //   return mimeTypeMatch ? mimeTypeMatch[1] : 'application/octet-stream';
  // }
  // : SafeUrl

  createFileLink(fileBase64: string, fileType: string, filename:string) {

    if (!filename) {
      console.warn('No file content available to download.');
      return; // Exit the function if file_content is null or empty
    }

    const a: any = document.createElement('a');
    a.href = fileBase64;
    a.download = filename;
    document.body.appendChild(a);
    a.style = 'display: none';
    a.click();
    a.remove();
    // let blob;
    // if (fileType === '.pdf') {
    //   blob = this.base64ToBlob(fileBase64, 'application/pdf');
    // } else if (fileType === '.png' || fileType === '.jpg' || fileType === '.jpeg') {
    //   blob = this.base64ToBlob(fileBase64, `image/${fileType.slice(1)}`);
    // } else if (fileType === '.docx') {
    //   blob = this.base64ToBlob(fileBase64, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
    // } else if (fileType === '.xls' || fileType === '.xlsx') {
    //   blob = this.base64ToBlob(fileBase64, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    // } else {
    //   blob = this.base64ToBlob(fileBase64, 'application/octet-stream');
    // }
  
    // const blobUrl = URL.createObjectURL(blob);
    // return this.sanitizer.bypassSecurityTrustUrl(blobUrl);
  }
  

  // base64ToBlob(base64: string, contentType: string) {
  //   const binaryString = window.atob(base64.replace(/^data:.+;base64,/, ''));
  //   const bytes = new Uint8Array(binaryString.length);
  //   for (let i = 0; i < binaryString.length; i++) {
  //     bytes[i] = binaryString.charCodeAt(i);
  //   }
  //   return new Blob([bytes], {type: contentType});
  // }

  
  // createDownloadLink(fileContent: string, fileExtension: string): SafeUrl {
  //   const mimeType = this.getMimeType(fileExtension);
  //   const fileUrl = `data:${mimeType};base64,${fileContent}`;
  //   return this.sanitizer.bypassSecurityTrustUrl(fileUrl);
  // }

  // createFileLink(fileContent: string, fileExtension: string): SafeUrl {
  //   const mimeType = this.getMimeType(fileExtension);
  //   const fileUrl = `data:${mimeType};base64,${fileContent}`;
  //   return this.sanitizer.bypassSecurityTrustUrl(fileUrl);
  // }

  // isImage(fileExtension: string): boolean {
  //   return ['.png', '.jpg', '.jpeg'].includes(fileExtension.toLowerCase());
  // }

  // private getMimeType(fileExtension: string): string {
  //   switch (fileExtension.toLowerCase()) {
  //     case '.png':
  //       return 'image/png';
  //     case '.jpg':
  //     case '.jpeg':
  //       return 'image/jpeg';
  //     case '.pdf':
  //       return 'application/pdf';
  //     case '.docx':
  //       return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  //     case '.xls':
  //     case '.xlsx':
  //       return 'application/vnd.ms-excel';
  //     default:
  //       return 'application/octet-stream';
  //   }
  // }

  // downloadFile(fileContent: string, fileExtension: string): void {
  //   const link = document.createElement('a');
  //   link.href = `data:${this.getMimeType(fileExtension)};base64,${fileContent}`;
  //   link.download = `filename${fileExtension}`; // Set the desired filename with extension
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // }

  // private getMimeType(fileExtension: string): string {
  //   switch (fileExtension.toLowerCase()) {
  //     case '.pdf':
  //       return 'application/pdf';
  //     case '.png':
  //     case '.jpg':
  //     case '.jpeg':
  //       return 'image/jpeg';
  //     case '.docx':
  //       return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  //     case '.xls':
  //       return 'application/vnd.ms-excel';
  //     default:
  //       return 'application/octet-stream';
  //   }
  // }

}

