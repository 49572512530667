export class FinancialClosure {
    public loiId: string;
    public capitalInvestment: string;
    public debt: string;
    public equity: string;
    public equitySecured: string;
    public reasonNotSecured: string;
    public issueWithLoan: string;
    public issueWithLoanDetails: string;
    public financeInstitute: string;
    public submit: number;
    public actor: number;
    public bankDistrict: string;
    public bankState: string;
    public otherBank: string;

    constructor(jsonObj=null){
        if(jsonObj){
            this.loiId = jsonObj['loi_system_id'];
            this.capitalInvestment = jsonObj['capital_investment_for_plant'];
            this.debt= jsonObj['debt_amount'];
            this.equity= jsonObj['equity_amount'];
            this.equitySecured= jsonObj['whether_investment_secured'];
            this.reasonNotSecured= jsonObj['reasons_for_not_securing'];
            this.issueWithLoan= jsonObj['constraints_from_financial_ins'];
            this.issueWithLoanDetails= jsonObj['details_of_constraint'];
            this.financeInstitute= jsonObj['name_of_financing_institute'];
            this.submit= jsonObj['form_status'];
            this.actor= jsonObj['record_role'];
            this.bankState = jsonObj["bank_state"];
            this.bankDistrict = jsonObj["bank_district"];
            this.otherBank = jsonObj["other_bank"];
        } else this.submit = 0;
    }

}