import { Component, OnInit } from '@angular/core';
import { HttpService } from '../services/http.service';
import { StoreService } from 'app/services/store.service';
//import { TimerObservable } from 'rxjs/observable/TimerObservable';
import { timer } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-highlights',
  templateUrl: './highlights.component.html',
  styleUrls: ['./highlights.component.css']
})
export class HighlightsComponent implements OnInit {

  stats = JSON.parse(sessionStorage.getItem('stats').toString());
  successMsg;
  errorMsg;
  showMsg: boolean= false;

  showDialog: boolean = true;

  constructor(
    private httpService :HttpService,
    private store: StoreService,
    private dialogRef: MatDialogRef<HighlightsComponent>,
  ) {
    
   }

  ngOnInit(): void {
    //console.log(this.stats);
  }

  save(){
    console.log("data is ",this.stats);
    if(this.stats.length>0){
      this.httpService.saveHighlights(this.stats[0].total_loi,this.stats[0].plants_commissioned,this.stats[0].gas_sales).subscribe((resp) =>{

          if(resp["status"]==1){
            sessionStorage.setItem('stats', JSON.stringify(this.stats));
              let res = resp["data"];
              this.successMsg = res["OUTPUT_MSG"];
              this.errorMsg = res["OUTPUT_MSG"];
  
              this.showMsg = true;
  
              timer(1000).subscribe(() => {
                this.showMsg = false;
                this.dialogRef.close();
              });
          }
      });
    }
  }

  close(){
    this.dialogRef.close();
  }
}
