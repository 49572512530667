export const environment = {
  production: true,

   //uat//
  apiUrl: "/satat/API",
  sendSMSUrl: "/satat/SendSMS",
  loginUrl: "/satat/login",
  uploadUrl: "/satat/Upload"
 
     //local// 
//   apiUrl: "http://localhost:8080/satat/API",
//   loginUrl: "http://localhost:8080/satat/login",
//   uploadUrl: "http://localhost:8080/satat/Upload"

  
  //production//
   // apiUrl: "/satat/API", 
   // loginUrl: "/satat/login",
   // uploadUrl: "/satat/Upload"
};


