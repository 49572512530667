export class BgDetails{
    public loiId: string;
    public submit: number;
    public actor: number;
    public bgStatus: string;
    public bgValidityDate: string;
    public caAgreementStatus: string;
    public caType: string;
    public caSigningDate: string;

    constructor(jsonObj =null)
    {
        if(jsonObj){
            this.loiId = jsonObj['loi_id'];
            this.submit = jsonObj['form_status'];
            this.actor = jsonObj['record_role'];
            this.bgStatus = jsonObj['bg_status'];
            this.bgValidityDate = new Date(jsonObj["bg_validity_date"]).toISOString().slice(0,10);
            this.caAgreementStatus = jsonObj['ca_agreement_status'];
            this.caType = jsonObj['ca_type'];
            this.caSigningDate = new Date(jsonObj["ca_signing_date"]).toISOString().slice(0,10);
            
        }
    }


}