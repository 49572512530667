import { Component, Input } from '@angular/core';
import { StoreService } from 'app/services/store.service';

@Component({
    selector: 'app-form-status',
    templateUrl: './form-status.component.html'
})

export class FormStatusComponent{
    @Input() status;
    get actor(){
        return this.store.actor;
    }

    constructor(private store: StoreService){
        
    }
    
}