import { Injectable } from '@angular/core';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment.prod';

@Injectable()
export class HttpService{

     apiUrl=environment.apiUrl; 
     loginUrl = environment.loginUrl;
    uploadUrl = environment.uploadUrl;
    constructor(private http: HttpClient){

    }

    sessionUser = null;

     //baseUrl = "https://fnogwlazcl2v6gv-crmatp.adb.ap-mumbai-1.oraclecloudapps.com/ords/satat_prod/satat/";
     //baseUrl = "https://fnogwlazcl2v6gv-crmatp.adb.ap-mumbai-1.oraclecloudapps.com/ords/cois_ws/satat/";

     //url = "http://localhost:8080/satat/API";
     //url = "/satat/API";
      url = this.apiUrl;


    public getChartData(path, param1, param2, param3){
        let data = {
             "service" : path,
             "data" : {
                 "param1" : param1,
                 "param2" : param2,
                 "param3" : param3
             }
         }
        return this.http.post(this.url, JSON.stringify(data));
    }

    public fetchStates()
    {
      let data = {
        "service" : 'lov-state'
      }
      return this.http.post(this.url, JSON.stringify(data));
    }
    public fetchDistricts(stateId)
    {
      let data = {
        "service" : 'lov-district',
        "data" : {"stateId" : stateId}
      }
      return this.http.post(this.url, JSON.stringify(data));
    }

    /*public getChartData(path, state, district, omc): Observable<any>{
        return this.http.get(this.baseUrl + path + '/' + state + '/' + district + '/' + omc);
    }

    public getDetailedReport(path, fromDate, toDate, omc): Observable<any>{
      return this.http.get(this.baseUrl + path + '/' + fromDate + '/' + toDate + '/' + omc);
    }

    public fetchStates(): Observable<any>{
      return this.http.get(this.baseUrl + 'lov-state');
   }

   public fetchDistricts(stateId): Observable<any>{
     return this.http.get(this.baseUrl + 'lov-state/' + stateId);
  }*/

}