import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from "ngx-toastr";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { ReportRoutes } from './report.routing';
import { HttpService } from './http.service';
import { LoginComponent } from './login/login.component';
import { DashboardMainComponent } from './dashboard-main/dashboard-main.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { FormsModule } from '@angular/forms';
import { LoaderComponent } from './loader/loader.component';
import { LoaderService } from './loader/loader.service';
import { LoaderInterceptor } from './loader/loader.interceptor';
import { NgxSpinnerModule } from "ngx-spinner";
import {DashboardDetailComponent} from './dashboard-detail/dashboard-detail.component';



@NgModule({
  declarations: [
    LoginComponent,
    DashboardMainComponent,
    DashboardComponent,
    LoaderComponent,
    DashboardDetailComponent
  ],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(ReportRoutes,{
      useHash: true
    }),
    HttpClientModule,
    SidebarModule,
    NavbarModule,
    ToastrModule.forRoot(),
    FooterModule,
    FormsModule,
    NgxSpinnerModule
  ],
  providers: [HttpService, LoaderService, { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }],
  //bootstrap: [AppComponent]
})
export class ReportModule { }
