import { DatePipe } from '@angular/common';

export class ProjectProgress {

    public loiId: string;
    public submit: number;
    public actor: number;
    public compressorProcurement: string;
    public cascadeProcurement: string;
    public noOfDigesters: string;
    public completionStatus: string;
    public digestorCompletion:string;
    public purificationSystem:string;
    public civilDigester:string;
    public ceremony:string;

    constructor(jsonObj = null){

        if(jsonObj){
            this.loiId = jsonObj['loi_status_id'];
            this.compressorProcurement = jsonObj['compresssor_procurement'];
            this.cascadeProcurement = jsonObj['cascade_procurement'];
            this.noOfDigesters = jsonObj['no_of_digesters'];
            this.completionStatus = jsonObj['completion_status'];
            this.submit = jsonObj['form_status'];
            this.actor = jsonObj['record_role'];
            this.digestorCompletion=jsonObj['DIGESTOR_COMPLETION'];
            this.purificationSystem=jsonObj['PURIFICATION_SYSTEM'];
            this.civilDigester = jsonObj['CIVIL_DIGESTER'];
            this.ceremony=jsonObj['CEREMONY'];
            
        } else this.submit = 0;
    }

    }