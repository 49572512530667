export class Basic {
  public loiId: string;
  public orgName: string;
  public loiRefNo: string;
  public state: string;
  public district: string;
  public omc: string;
  public email : string;
  public mobile : string;
  public loiIssueDate : string;
  public operationCommenced : string;
  public proposedCommencementDate: string;
  public plantStatus : string;
  public loiValidity : string;
  public plantCapacity : number;
  public locationPlant: string;
  public locationLat: string;
  public locationLong: string;
  public submit: number;
  public actor: number;
  public vendorId: string;
  public createVendor: number;

  constructor(jsonObj = null){
    if(jsonObj){
      this.loiId = jsonObj["loi_system_id"];
      this.orgName = jsonObj["organisation_name"];
      this.loiRefNo = jsonObj["loi_reference_number"];
      this.state = jsonObj["plant_state"];
      this.district = jsonObj["plant_district"];
      this.omc = jsonObj["omc"];
      this.email = jsonObj["email_id"];
      this.mobile = jsonObj["mobile_no"];
      this.loiIssueDate = new Date(jsonObj["issue_date"]).toISOString().slice(0,10);
      this.operationCommenced = jsonObj["operation_commenced"]
      this.proposedCommencementDate = new Date(jsonObj["plant_commission_date"]).toISOString().slice(0,10);
      this.plantStatus = jsonObj["project_type"];
      this.loiValidity = new Date(jsonObj["validity_date"]).toISOString().slice(0,10);
      this.plantCapacity = jsonObj["plant_capacity"];
      this.locationPlant = jsonObj["locationp"];
      this.locationLat = jsonObj["latitude"];
      this.locationLong = jsonObj["longitude"];
      this.submit = jsonObj["form_status"];
      this.actor = jsonObj["record_role"];
    }

  }
  
}