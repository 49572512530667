export class FertilizerMarketing {
    public loiId: string;
    public fertilizerSale: string;
    public agreementPartyDetails: string;
    public biofertilizerBrand: string;
    public marketingIssue: string;
    public marketingIssueDetails: string;
    public submit: number;
    public actor : number;

    constructor(jsonObj=null){
        if(jsonObj){
            this.loiId= jsonObj['loi_object_id'];
            this.fertilizerSale = jsonObj['agreement_for_fertilizers_sale'];
            this.agreementPartyDetails=jsonObj['agreement_party_details'];
            this.biofertilizerBrand=jsonObj['own_biofertilizer_brand'];
            this.marketingIssue=jsonObj['constraint_in_marketing_fertilizers'];
            this.marketingIssueDetails=jsonObj['marketing_constraint_details'];
            this.submit=jsonObj['form_status'];
            this.actor=jsonObj['record_role'];
        } else this.submit = 0;
    }
}