import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from '../services/http.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  moduleId : module.id,
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public username;
  public password;
  public isValid = true;

  constructor(
    private httpService : HttpService,
    private router: Router) { }

  ngOnInit(): void {
  }

  login() {
    let resp = this.httpService.authenticate(this.username, this.password);
    if(resp){
      this.isValid = true;
      this.router.navigate(['/home']);
    } else this.isValid = false;
    /*this.httpService.authenticate(this.username, this.password).subscribe(res => {
        if(res["status"] == "1"){
            this.isValid = true;
            this.router.navigate(['dashboard'], {skipLocationChange : true});
        }else this.isValid = false;
    });*/
    }

}
