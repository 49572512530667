import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pages-comissioned-plants',
  templateUrl: './pages-comissioned-plants.component.html',
  styleUrls: ['./pages-comissioned-plants.component.css']
})
export class PagesComissionedPlantsComponent implements OnInit {

  id:string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    console.log("id",this.id);
    var elements = document.getElementById(this.id);

      elements.scrollIntoView();
  }

}
