import { Component } from '@angular/core';
import { Basic } from '../models/basic';
import { LandFinalization } from '../models/landFinalization';
import { FinancialClosure } from '../models/financialClosure';
import { Feedstock } from '../models/feedstock';
import { FertilizerMarketing } from '../models/fertilizerMarketing';
import { Technology } from '../models/technology';
import { ProjectProgress } from '../models/projectProgress';
import { RO, RoMapping } from '../models/roMapping';
import { HttpService } from '../../services/http.service';
import { Approvals } from '../models/approvals';
import { BgDetails } from '../models/bgDetails';
import { LOV_TYPE } from 'app/shared/Constants';
import { Inject }  from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { StoreService } from 'app/services/store.service';
import { Router } from '@angular/router';


@Component({
    selector: 'form-cmp',
    templateUrl: './form.component.html'
})
export class FormComponent
{
    submitted: boolean = false;
    basicDetails: Basic;  
    landFinalizationDetails: LandFinalization;  
    financialClosure: FinancialClosure;
    fertilizerMarketing: FertilizerMarketing;
    feedstock: Feedstock;
    technology: Technology;
    projectProgress: ProjectProgress;
    roMapping: RoMapping;
    approvals: Approvals;
    bgDetails: BgDetails;
    successMsg = "Record saved successfully";
    errorMsg = "Record could not be saved. Please try again later or check your input";

    landFinalizationResponseStatus = -1;
    basicDetailsResponseStatus = -1;
    financialClosureResponseStatus = -1;
    feedstockResponseStatus = -1;
    fertilizerMarketingResponseStatus = -1;
    technologyResponseStatus = -1;
    projectProgressResponseStatus = -1;
    roMappingResponseStatus = -1;
    approvalsResponseStatus = -1;
    bgDetailsResponseStatus = -1;

    submitBtnText = "Submit";
    
    response;

    get ros(){
      return this.store.ros;
    }
    setRoCode(indx){
      let ro:RO = this.roMapping.ro[indx];
      let selectedRo = this.ros.find(x => x.ro_name == ro.roName);
      this.roMapping.ro[indx].roCode = selectedRo.ro_id;
    }
    get states(){
      return this.store.states;
    }
    get banks(){
      return this.store.banks;
    }
    get omcs(){
      return this.store.omcs;
    }
    get plantTechnologies(){
      return this.store.plantTechnologies;
    }
    get districts(){
      return this.store.districts;
    }
    get bankDistricts(){
      return this.store.bankDistricts;
    }
    get feedstockTypes(){
      return this.store.feedstockTypes;
    }
    get actor(){
      return this.store.actor;
    }
    get loiId(){
      return this.store.loiId;
    }
    get loiRefNo(){
      return this.store.loiRefNo;
    }
    get unitStatusLov(){
      return this.store.unitStatusLov;
  }

  get approvalStatusLovs(){
      return this.store.approvalStatusLovs;
  }

  get usernameout(){
    return this.store.usernameout;
}



    selectedState;
    selectedBankState;
    selectedRoState;
    selectedRoDistrict;
    roState;
    roDistricts;
    loiCompletionStatus;

    _basicLoaded = false;
    _landFinalizationLoaded = false;
    _financialClosureLoaded = false;
    _feedstockLoaded = false;
    _fertilizerLoaded = false;
    _technologyLoaded = false;
    _projectProgressLoaded = false;
    _approvalsLoaded = false;
    _bgDetailsLoaded = false;

    get isLoaded(){
      return this._basicLoaded && this._landFinalizationLoaded && 
             this._financialClosureLoaded && this._feedstockLoaded &&
             this._fertilizerLoaded && this._technologyLoaded && this._projectProgressLoaded
             && this._approvalsLoaded && this._bgDetailsLoaded;
    } 
    fileToUpload: File = null;

    handleFileInput(files: FileList) {
      this.fileToUpload = files.item(0);
     // console.log(this.fileToUpload);
  }

  uploadDoc(files: File[]){
    //pick from one of the 4 styles of file uploads below
    var formData = new FormData();
    Array.from(files).forEach(f => formData.append('file',f));
    this.httpService.uploadFiles(formData);
  }


    ngOnInit()
    {
      /*
      if(!this.ros)
      {
        this.httpService.getRos(this.store.omc,).subscribe((resp) => {
          //if(resp["status"]==1){
            let res = resp;
            //let res = resp["data"];
            this.store.ros = res["items"]
          //} else if(resp["status"]==403) this.router.navigate["/"];
        }
        );
      }

      this.httpService.getDetails('Satat_Form_BasicDetails', this.store.loiId).subscribe(
        (resp) => {
          //console.log(resp);
          //if(resp["STATUS"]==1){
            let res = resp;
            // let res = resp;
            if(res.count>0)this.basicDetails = new Basic(res.items[0]);
            else this.basicDetails = new Basic();
            this._basicLoaded = true;
            this.selectedState = this.states.find(s => s.state == this.basicDetails.state);
            this.fetchDistricts(this.selectedState.state_id, false);
          //} else if(resp["status"]==403) this.router.navigate["/"]; 
        },
        (err) => this.basicDetails = null
      );

      this.httpService.getDetails('Satat_Form_LandFinalization', this.store.loiId).subscribe(
        (resp) => {
          //console.log(resp);
          //if(resp["status"]==1){
            //let res = resp["data"];
           let res = resp;
            if(res.count>0)this.landFinalizationDetails = new LandFinalization(res.items[0]);
            else this.landFinalizationDetails = new LandFinalization();
            this._landFinalizationLoaded = true;
          //} else if(resp["status"]==403) this.router.navigate["/"];
        },
        (err) => this.landFinalizationDetails = null
      );

      this.httpService.getDetails('Satat_Form_FinancialClosure', this.store.loiId).subscribe(
        (resp) => {
          //console.log(resp);
          //if(resp["status"]==1){
            //let res = resp["data"];
            let res = resp;
            if(res.count>0)this.financialClosure = new FinancialClosure(res.items[0]);
            else this.financialClosure = new FinancialClosure();
            this.selectedBankState = this.states.find(s => s.state == this.financialClosure.bankState);
            if(this.selectedBankState)this.fetchBankDistricts(this.selectedBankState.state_id, false);
            this._financialClosureLoaded = true;
          //} else if(resp["status"]==403) this.router.navigate["/"];
        },
        (err) => this.financialClosure = null
      );

      this.httpService.getDetails('Satat_Form_Feedstock', this.store.loiId).subscribe(
        (resp) => {
          //if(resp["status"]==1){
           // let res = resp["data"];
           let res = resp;
            if(res.count>0)this.feedstock = new Feedstock(res.items[0]);
            else {
              this.feedstock = new Feedstock();
              //this.feedstock.primaryFeedstockType = this.feedstockTypes[0];
              //this.feedstock.secondaryFeedstockType = this.feedstockTypes[0];
            }
            this._feedstockLoaded = true;
          //} else if(resp["status"]==403) this.router.navigate["/"];
        },
        (err) => this.feedstock = null
      );

      this.httpService.getDetails('Satat_Form_Fertilizer', this.store.loiId).subscribe(
        (resp) => {

         // if(resp["status"]==1){
           // let res = resp["data"];
         let res = resp;
            if(res.count>0)this.fertilizerMarketing = new FertilizerMarketing(res.items[0]);
            else this.fertilizerMarketing = new FertilizerMarketing();
            this._fertilizerLoaded = true;
          //} else if(resp["status"]==403) this.router.navigate["/"];
        },
        (err) => this.fertilizerMarketing = null
      );
      
      this.httpService.getDetails('Satat_Form_Technology', this.store.loiId).subscribe(
        (resp) => {
          //if(resp["status"]==1){
            //let res = resp["data"];
            let res = resp;
            if(res.count>0)this.technology = new Technology(res.items[0]);
            else {
              this.technology = new Technology();
              //this.technology.plantTechnology = this.plantTechnologies[0];
            }
              this._technologyLoaded = true;
          //} else if(resp["status"]==403) this.router.navigate["/"];
        },
        (err) => this.technology = null
      );
      
      this.httpService.getDetails('Satat_Form_ProjectProgress', this.store.loiId).subscribe(
        (resp) => {
          //if(resp["status"]==1){
            //let res = resp["data"];
             let res = resp;
            if(res.count>0)this.projectProgress = new ProjectProgress(res.items[0]);
            else this.projectProgress = new ProjectProgress();
            this._projectProgressLoaded = true;
          //} else if(resp["status"]==403) this.router.navigate["/"];
        },
        (err) => this.projectProgress = null
      );

      this.httpService.getDetails('Satat_Form_StatutoryApprovals', this.store.loiId).
      subscribe((resp) => {
           let res = resp;
            if(res.count>0)this.approvals = new Approvals(res.items[0]);
            else this.approvals = new Approvals();
            this._approvalsLoaded = true;
        },
        (err) => this.approvals = null
      );

      this.httpService.getDetails('Satat_Form_BgDetails', this.store.loiId).
      subscribe((resp) => {
            let res = resp;
            if(res.count>0)this.bgDetails= new BgDetails(res.items[0]);
            else this.bgDetails = new BgDetails();
            this._bgDetailsLoaded = true;
        },
        (err) => this.bgDetails = null
      );*/
      

      //Code for Java Project
      /*
      if(!this.ros)
      {
        this.httpService.getRos().subscribe((resp) => {
          if(resp["status"]==1){
            let res = resp["data"];
            this.store.ros = res["items"]
          } else if(resp["status"]==403) this.router.navigate["/"];
        }
        );
      }*/

      this.httpService.getCBGDataPoints(this.store.loiId, this.store.usernameout).subscribe(
        (resp) => {
        //  console.log("****LOI Completoin Status*********");
          if(resp["status"]==1){
            //let res = resp["data"];
            //let data = res["items"];
            let res=resp;
            let data = res["data"];
            //console.log(this.loiCompletionStatus)
          }  
        },
      );

      this.httpService.getDetails('get-loi-status', this.store.loiId).subscribe(
        (resp) => {
        //  console.log("****LOI Completoin Status*********");
          if(resp["status"]==1){
            //let res = resp["data"];
            //let data = res["items"];
            let res=resp;
            let data = res["data"];
            this.loiCompletionStatus = data.items[0].percentage;
            //console.log(this.loiCompletionStatus);
            
            //console.log("&&&&&&&&&&&&&&&&");
            
          }  
        },
      );

      this.httpService.getDetails('get-basic-details', this.store.loiId).subscribe(
        (resp) => {
        //  console.log("****Basic Details Response*********")
        //  console.log(resp);
          if(resp["status"]==1){
            let res = resp["data"];
            if(res.count>0)this.basicDetails = new Basic(res.items[0]);
            else this.basicDetails = new Basic();
            //console.log("***********Form Status**************");
          //  console.log(this.basicDetails.submit);
            this._basicLoaded = true;
            this.selectedState = this.states.find(s => s.state == this.basicDetails.state);
         //   console.log("********Selecected state is*****");
          //  console.log(this.selectedState);
            this.fetchDistricts(this.selectedState.state_id, false);
          } else if(resp["status"]==403) this.router.navigate["/"]; 
        },
        (err) => this.basicDetails = null
      );

        this.httpService.getDetails('get-land-finalization', this.store.loiId).subscribe(
        (resp) => {
          //  console.log("****Land Finalization Response*********")
          //  console.log(resp);
            if(resp["status"]==1){
              let res = resp["data"];
              if(res.count>0)this.landFinalizationDetails = new LandFinalization(res.items[0]);
              else this.landFinalizationDetails = new LandFinalization();
              this._landFinalizationLoaded = true;
           //   console.log("***********Form Status**************");
            //  console.log(this.landFinalizationDetails.submit);

          } else if(resp["status"]==403) this.router.navigate["/"];
        },
        (err) => this.landFinalizationDetails = null
      );

        this.httpService.getDetails('get-financial-closure', this.store.loiId).subscribe(
          (resp) => {
          //  console.log("****Financial Detail*********")
           // console.log(resp);
            if(resp["status"]==1){
              let res = resp["data"];
              
              if(res.count>0)this.financialClosure = new FinancialClosure(res.items[0]);
              else this.financialClosure = new FinancialClosure();
            //  console.log(this.banks);
            //  console.log(this.financialClosure);
              this.selectedBankState = this.states.find(s => s.state == this.financialClosure.bankState);
              if(this.selectedBankState)this.fetchBankDistricts(this.selectedBankState.state_id, false);
              this._financialClosureLoaded = true;
            } else if(resp["status"]==403) this.router.navigate["/"];
          },
          (err) => this.financialClosure = null
        ); 
        
        this.httpService.getDetails('get-feedstock', this.store.loiId).subscribe(
        (resp) => {
        //  console.log("***Feedstock Response*********")
        //  console.log(resp);
          if(resp["status"]==1){
            let res = resp["data"];
          //  console.log(res);
            if(res.count>0)this.feedstock = new Feedstock(res.items[0]);
            
            else {
              this.feedstock = new Feedstock();
              //this.feedstock.primaryFeedstockType = this.feedstockTypes[0];
              //this.feedstock.secondaryFeedstockType = this.feedstockTypes[0];
            }
            this._feedstockLoaded = true;
          } else if(resp["status"]==403) this.router.navigate["/"];
        },
        (err) => this.feedstock = null
      );
      
        this.httpService.getDetails('get-fertilizer', this.store.loiId).subscribe(
          (resp) => {
          //  console.log("*****Fertilizer Response*********")
          //  console.log(resp);
           if(resp["status"]==1){
            let res = resp;
              if(res.count>0)this.fertilizerMarketing = new FertilizerMarketing(res.items[0]);
              else this.fertilizerMarketing = new FertilizerMarketing();
              this._fertilizerLoaded = true;
            } else if(resp["status"]==403) this.router.navigate["/"];
          },
          (err) => this.fertilizerMarketing = null
        );

      this.httpService.getDetails('get-plant-technology', this.store.loiId).subscribe(
          (resp) => {
          //  console.log("****Plant Response*********")
          //  console.log(resp);
            if(resp["status"]==1){
              let res = resp["data"];
              if(res.count>0)this.technology = new Technology(res.items[0]);
              else {
                this.technology = new Technology();
                //this.technology.plantTechnology = this.plantTechnologies[0];
              }
                this._technologyLoaded = true;
            } else if(resp["status"]==403) this.router.navigate["/"];
          },
          (err) => this.technology = null
        );


      this.httpService.getDetails('get-bg-details', this.store.loiId).
      subscribe((resp) => {
          //  console.log("****BG Response*********")
          //  console.log(resp);
            let res = resp["data"];
            if(res.count>0)this.bgDetails= new BgDetails(res.items[0]);
            else this.bgDetails = new BgDetails();
            this._bgDetailsLoaded = true;
        },
        (err) => this.bgDetails = null
      );
      
      this.httpService.getDetails('get-project-progress', this.store.loiId).subscribe(
          (resp) => {
          //  console.log("****PP Response*********")
          //  console.log(resp);
            if(resp["status"]==1){
              let res = resp["data"];
              if(res.count>0)this.projectProgress = new ProjectProgress(res.items[0]);
              else this.projectProgress = new ProjectProgress();
              this._projectProgressLoaded = true;
            } else if(resp["status"]==403) this.router.navigate["/"];
          },
          (err) => this.projectProgress = null
        );
      
        this.httpService.getDetails('get-approvals', this.store.loiId).subscribe(
          (resp) => {
          //  console.log("****Approvl Response*********")
          //  console.log(resp);
            if(resp["status"]==1){
              let res = resp["data"];
              if(res.count>0)this.approvals = new Approvals(res.items[0]);
              else this.approvals = new Approvals();
              this._approvalsLoaded = true;
            } else if(resp["status"]==403) this.router.navigate["/"];
          },
          (err) => this.approvals = null
        );
     
      this.basicDetails = new Basic();
      //this.basicDetails.omc = this.omcs[0];
     // this.basicDetails.district = this.districts[0];
      this.roMapping = new RoMapping();
    }
    constructor(
      private httpService: HttpService, 
      @Inject(DOCUMENT) document,
      private store: StoreService,
      private router : Router)
      {
        if(this.actor==2) this.submitBtnText = "Approve";
        
        if(!this.states)
        {       
          this.httpService.fetchLov(LOV_TYPE.StateLOV).subscribe((resp)=>{
            if(resp["status"]==1){
            //  console.log("states response");
            //  console.log(resp);
              let res = resp["data"];
              this.store.states = res["items"];
              this.selectedState = this.states[0];
              this.selectedBankState = this.states[0];
            } else if(resp["status"]==403) this.router.navigate["/"];
            
          });
        }
        if(!this.banks)
        {
          
              
          this.httpService.fetchLov(LOV_TYPE.BankLOV).subscribe((resp)=> {
            //if(resp["STATUS"]==1){
              let res = resp["data"];
              //let res = resp;
              
              this.store.banks = res["items"].map(r => r.bank_name);
              
              
              //this.financialClosure.financeInstitute = this.httpService.banks[0];
            //} else if(resp["STATUS"]==0) this.router.navigate["/"];
          });
        } 

        if(!this.plantTechnologies)
          {
            this.httpService.fetchLov(LOV_TYPE.PlantTechnologyLOV).subscribe(
              (resp) => {
                if(resp["status"]==1){
                  let res = resp["data"];
                  this.store.createLovs(res["items"]);
                } else if(resp["status"]==0) this.router.navigate["/"];
              }
            )
        }

        if(!this.roState)
        {
          this.selectedRoState = this.states[0];
        }

        /*
        if(!this.states)
          this.httpService.fetchLov(LOV_TYPE.StateLOV).subscribe((resp)=>{
            //if(resp["STATUS"]==1){
            // let res = resp["data"];
              let res = resp;
              this.store.states = res["items"];
              this.selectedState = this.states[0];
              this.selectedBankState = this.states[0];
              //this.fetchDistricts(this.selectedState.state_id, true);
            //} else if(resp["STATUS"]==0) this.router.navigate["/"];
            
          });
        

        if(!this.banks)
        this.httpService.fetchLov(LOV_TYPE.BankLOV).subscribe((resp)=> {
          if(resp["status"]==1){
            let res = resp["data"];
            this.store.banks = res["items"].map(r => r.bank_name);
            //this.financialClosure.financeInstitute = this.httpService.banks[0];
          } else if(resp["status"]==403) this.router.navigate["/"];
          
        });

        if(!this.plantTechnologies)
        {
          this.httpService.fetchLov(LOV_TYPE.PlantTechnologyLOV).subscribe(
            (resp) => {
              //console.log("%%%%%%%%%%%%%%%%%%%%%%%%");
              //console.log(resp);
              if(resp["status"]==1){
                let res = resp["data"];
          //  let res = resp;
                this.store.createLovs(res["items"]);
              } else if(resp["status"]==403) this.router.navigate["/"];
            }
          )
        }*/


        /*
        {
          this.httpService.fetchLov(LOV_TYPE.PlantTechnologyLOV).subscribe(
            (resp) => {
              //if(resp["STATUS"]==1){
                //let res = resp["data"];
                let res = resp;
                this.store.createLovs(res["items"]);
              //} else if(resp["STATUS"]==0) this.router.navigate["/"];
            }
          )
        }
        */
    }
    /*
    fetchDistricts(stateId, setValue){
      this.httpService.fetchLov(LOV_TYPE.DistrictLOV, stateId).subscribe((resp)=>{
        console.log("District Response IS");
        console.log(resp);
        //if(resp["status"]==1){
          //let res = resp["data"];
         let res = resp;
          this.store.districts = res["items"].map(r => r.district);
           if(setValue) this.basicDetails.district = this.store.districts[0];
        //} else if(resp["status"]==403) this.router.navigate["/"];
      });
    }
    fetchBankDistricts(stateId, setValue){
      this.httpService.fetchLov(LOV_TYPE.DistrictLOV, stateId).subscribe((resp)=>{
        //if(resp["status"]==1){
         // let res = resp["data"];
         let res = resp;
          this.store.bankDistricts = res["items"].map(r => r.district);
           if(setValue) this.financialClosure.bankDistrict = this.store.bankDistricts[0];
        //} else if(resp["status"]==403) this.router.navigate["/"];
      });
    }*/
    fetchRoDistricts(stateId, setValue){
      this.httpService.fetchLov(LOV_TYPE.DistrictLOV, stateId).subscribe((resp)=>{
        if(resp["status"]==1){
          let res = resp["data"];
         
       //  console.log("Response of Districts");
       //  console.log(res);
         this.roDistricts = res["items"].map(r => r.district);
           if(setValue) this.selectedRoDistrict = this.store.roDistricts[0];
           
        } else if(resp["status"]==403) this.router.navigate["/"];
      });
    }
    /*
    fetchRoDistricts(stateId, setValue){
      this.httpService.fetchLov(LOV_TYPE.DistrictLOV, stateId).subscribe((resp)=>{
        //if(resp["status"]==1){
         // let res = resp["data"];
         let res = resp;
         console.log("Response of Districts");
         console.log(res);
         this.roDistricts = res["items"].map(r => r.district);
           if(setValue) this.selectedRoDistrict = this.store.roDistricts[0];
           
        //} else if(resp["status"]==403) this.router.navigate["/"];
      });
    }*/
    stateSelected(){
      this.basicDetails.state = this.selectedState.state;
      this.fetchDistricts(this.selectedState.state_id, true);
    }
    bankStateSelected(){
      this.financialClosure.bankState = this.selectedBankState.state;
      this.fetchBankDistricts(this.selectedBankState.state_id, true);
    }

    roStateSelected(){
      this.roState = this.selectedRoState.state;
    //  console.log("STATE Selected is "+this.roState);
    //  console.log(this.roState);
      this.fetchRoDistricts(this.selectedRoState.state_id, true);
    }

    /*
    roDistrictSelected(){  
        this.httpService.getRosForOutlet(this.store.omc,this.roState,
          this.selectedRoDistrict).subscribe((resp) => {
            let res = resp;
            console.log("Response of RO IDS");
            console.log(res);
            this.store.ros = res["items"]
        }
        );
    }*/
    roDistrictSelected(){  
      this.httpService.getRos(this.store.omc,this.roState,
        this.selectedRoDistrict).subscribe((resp) => {
       // console.log("Ro District Response");
       // console.log(resp);
        if(resp["status"]==1){
          let res = resp["data"];
          this.store.ros = res["items"]
        } else if(resp["status"]==403) this.router.navigate["/"];
      }
      );
  }

 

    isVisible(status)
    {
      if(this.actor == 1){
        return true;
      } 
      else if(status > 1)
      {
        return true;
      }
      return false;
    }
    isDisabled(status){
      if(this.actor == 1 && status > 1){
        return true;
      } else if(status > 3){
        return true;
      }
      return false;
    }
/*
    onSubmitBasicDetails(status) {
      if(this.actor==2)status = status + 2;
        this.basicDetails.submit = status;
        //this.httpService.updateDetails('save-basic-details', this.basicDetails, this.store.loiId, this.store.actor).subscribe(
        this.httpService.updateDetails('Satat_Form_BasicDetails', this.basicDetails,
        this.store.loiId, this.store.actor).subscribe(
          resp => {
           // if(resp["status"]==1){
            //  let res = resp["data"];
            let res = resp;
              this.basicDetailsResponseStatus = res["STATUS"];
            //} else if(resp["status"]==403) this.router.navigate["/"];
          }
        );
      }
    onSubmitLandFinalization(status) {
      if(this.actor==2)status = status + 2;
      this.landFinalizationDetails.submit = status;
      //this.httpService.updateDetails('save-land-finalization', this.landFinalizationDetails, this.store.loiId, this.store.actor).subscribe(
      this.httpService.updateDetails('Satat_Form_LandFinalization', this.landFinalizationDetails,
      this.store.loiId, this.store.actor).subscribe(
        resp => {
          //if(resp["status"]==1){
            // let res = resp["data"];
          let res = resp;
            this.landFinalizationResponseStatus = res["STATUS"];
          //} else if(resp["status"]==403) this.router.navigate["/"];
        }
      );

    }
    onSubmitFinancialClosure(status){
      if(this.actor==2)status = status + 2;
      this.financialClosure.submit = status;
      //this.httpService.updateDetails('save-financial-closure', this.financialClosure, this.store.loiId, this.store.actor).subscribe(
      this.httpService.updateDetails('Satat_Form_FinancialClosure', this.financialClosure,
      this.store.loiId, this.store.actor).subscribe(
        (resp) => {
          //if(resp["status"]==1){
            //let res = resp["data"];
            let res = resp;
            this.financialClosureResponseStatus = res["STATUS"];
          //} else if(resp["status"]==403) this.router.navigate["/"];
        },
        (err) => this.financialClosureResponseStatus = 0
      );
    }
    onSubmitFeedstock(status){
      if(this.actor==2)status = status + 2;
      this.feedstock.submit = status;
      // this.httpService.updateDetails('save-feedstock', this.feedstock, this.store.loiId, this.store.actor).subscribe(
      this.httpService.updateDetails('Satat_Form_Feedstock', this.feedstock,
      this.store.loiId, this.store.actor).subscribe(
        resp => {
          //if(resp["status"]==1){
          //  let res = resp["data"];
          let res = resp;
            this.feedstockResponseStatus = res["STATUS"];
          //} else if(resp["status"]==403) this.router.navigate["/"];  
        }
      );;
    }
    onSubmitFertilizerMarketing(status){
      if(this.actor==2)status = status + 2;
      this.fertilizerMarketing.submit = status;
      this.httpService.updateDetails('save-fertilizer', this.fertilizerMarketing, 
      this.store.loiId, this.store.actor).subscribe(
        resp => {
          if(resp["status"]==1){
            let res = resp["data"];
            this.fertilizerMarketingResponseStatus = res["STATUS"];
          } else if(resp["status"]==403) this.router.navigate["/"];
        }
      );;
    }
    onSubmitTechnology(status){
      if(this.actor==2)status = status + 2;
      this.technology.submit = status;
      //this.httpService.updateDetails('save-plant-technology', this.technology, this.store.loiId, this.store.actor).subscribe(
      this.httpService.updateDetails('Satat_Form_Technology', this.technology,
      this.store.loiId, this.store.actor).subscribe(
        resp => {
          //if(resp["status"]==1){
            // let res = resp["data"];
            let res = resp;
            this.technologyResponseStatus = res["STATUS"];
          //} else if(resp["status"]==403) this.router.navigate["/"];
        }
      );;
    }
    onSubmitProjectProgress(status){
      if(this.actor==2)status = status + 2;
      this.projectProgress.submit = status;
      //this.httpService.updateDetails('save-project-progress', this.projectProgress, this.store.loiId, this.store.actor).subscribe(
      this.httpService.updateDetails('Satat_Form_ProjectProgress', this.projectProgress,
      this.store.loiId, this.store.actor).subscribe(
        resp => {
          //if(resp["status"]==1){
            // let res = resp["data"];
            let res = resp;
            this.projectProgressResponseStatus = res["STATUS"];
          //} else if(resp["status"]==403) this.router.navigate["/"];
        } 
      );
    }
    onSubmitApprovals(status){
      if(this.actor==2)status = status + 2;
      this.approvals.submit = status;
      this.httpService.updateDetails('Satat_Form_StatutoryApprovals', this.approvals,
        this.store.loiId, this.store.actor).subscribe(
        (resp) => {
          let res = resp;
            this.approvalsResponseStatus = res["STATUS"];
            if(this.approvalsResponseStatus!=1)this.approvals.submit=status;
        },
        (err) => {
          this.approvalsResponseStatus = 0;
          this.approvals.submit=status;
        }
      );
    }

    onSubmitBgDetails(status){
      if(this.actor==2)status = status + 2;
      this.bgDetails.submit = status;
    
      this.httpService.updateDetails('Satat_Form_BgDetails', this.bgDetails,
        this.store.loiId, this.store.actor).subscribe(
        (resp) => {
          let res = resp;
            this.bgDetailsResponseStatus = res["STATUS"];
            if(this.bgDetailsResponseStatus!=1)this.bgDetails.submit=status;
        },
        (err) => {
          this.bgDetailsResponseStatus = 0;
          this.bgDetails.submit=status;
        }
      );
    }
    onSubmitRo(status){
      if(this.actor==2)status = status + 2;
      this.roMapping.submit=status;
      console.log(this.roMapping);
      this.roMapping.roArray = JSON.stringify(this.roMapping.ro);
      //this.httpService.updateDetails('save-ro-mapping', this.roMapping, this.store.loiId, this.store.actor).subscribe(
      this.httpService.updateDetails('Satat_Form_RO_Allocation_Data', this.roMapping,
      this.store.loiId, this.store.actor).subscribe(
        (resp) => {
         // if(resp["status"]==1){
          //  let res = resp["data"];
        let res = resp;
       this.roMappingResponseStatus = res["STATUS"];
         // } else if(resp["status"]==403) this.router.navigate["/"];
          
        },
        (err) => {
          this.roMappingResponseStatus = 0;
          
        }
      );
     
    }

    addRo(){
      this.roMapping.ro.push(new RO());
    }
    removeRo(){
      //this.roMapping.ro.splice(-1,1);
      this.roMapping.ro.pop();
    }*/
    //Code for Java Starts here
    
    fetchDistricts(stateId, setValue){
      this.httpService.fetchLov(LOV_TYPE.DistrictLOV, stateId).subscribe((resp)=>{
        if(resp["status"]==1){
          let res = resp["data"];
          this.store.districts = res["items"].map(r => r.district);
           if(setValue) this.basicDetails.district = this.store.districts[0];
        } else if(resp["status"]==403) this.router.navigate["/"];
      });
    }
  
    fetchBankDistricts(stateId, setValue){
      this.httpService.fetchLov(LOV_TYPE.DistrictLOV, stateId).subscribe((resp)=>{
          if(resp["status"]==1){
            let res = resp["data"];
            this.store.bankDistricts = res["items"].map(r => r.district);
            if(setValue) this.financialClosure.bankDistrict = this.store.bankDistricts[0];
        } else if(resp["status"]==403) this.router.navigate["/"];
      });
    }
    /*
    stateSelected(){
      this.basicDetails.state = this.selectedState.state;
      this.fetchDistricts(this.selectedState.state_id, true);
    }

    bankStateSelected(){
      this.financialClosure.bankState = this.selectedBankState.state;
      this.fetchBankDistricts(this.selectedBankState.state_id, true);
    }

    isVisible(status)
    {
      if(this.actor == 1){
        return true;
      } 
      else if(this.actor == 2 && status > 1)
      {
        return true;
      }
      return false;
    }

    isDisabled(status){
      if(this.actor == 1 && status > 1){
        return true;
      } else if(this.actor == 2 && status > 3){
        return true;
      }
      return false;
    }*/

    onSubmitBasicDetails(status) {
      if(this.actor==2)status = status + 2;
        this.basicDetails.submit = status;
        console.log("Submit option is:", this.basicDetails.submit);
        this.httpService.updateDetails('save-basic-details', this.basicDetails,
        this.store.loiId, this.store.actor).subscribe(
          resp => {
            //console.log("*********on submit response*******")
            //console.log(resp);
           if(resp["status"]==1){
              let res = resp["data"];
              this.basicDetailsResponseStatus = res["STATUS"];
            } else if(resp["status"]==403) this.router.navigate["/"];
          }
        );
      }

      onSubmitLandFinalization(status) {
        if(this.actor==2)status = status + 2;
        this.landFinalizationDetails.submit = status;
        this.httpService.updateDetails('save-land-finalization', this.landFinalizationDetails,
        this.store.loiId, this.store.actor).subscribe(
          resp => {
            
          //  console.log("*********on submit response*******")
          //  console.log(resp);
            if(resp["status"]==1){
              let res = resp["data"];
              this.landFinalizationResponseStatus = res["STATUS"];
              } else if(resp["status"]==403) this.router.navigate["/"];
          }
        );
      }

     onSubmitFinancialClosure(status){
      if(this.actor==2)status = status + 2;
      this.financialClosure.submit = status;
      this.httpService.updateDetails('save-financial-closure', this.financialClosure, 
      this.store.loiId, this.store.actor).subscribe(
        (resp) => {
          if(resp["status"]==1){
            let res = resp["data"];
            this.financialClosureResponseStatus = res["STATUS"];
          } else if(resp["status"]==403) this.router.navigate["/"];
        },
        (err) => this.financialClosureResponseStatus = 0
      );
    }
      
     onSubmitFeedstock(status){
      if(this.actor==2)status = status + 2;
      this.feedstock.submit = status;
      this.httpService.updateDetails('save-feedstock', this.feedstock, 
      this.store.loiId, this.store.actor).subscribe(
        resp => {
         if(resp["status"]==1){
            let res = resp["data"];
            this.feedstockResponseStatus = res["STATUS"];
          } else if(resp["status"]==403) this.router.navigate["/"];  
        }
      );;
    }
  
      onSubmitFertilizerMarketing(status){
        if(this.actor==2)status = status + 2;
        this.fertilizerMarketing.submit = status;
        this.httpService.updateDetails('save-fertilizer', this.fertilizerMarketing, this.store.loiId, this.store.actor).subscribe(
        //this.httpService.updateDetails('Satat_Form_Fertilizer', this.fertilizerMarketing,
        //this.store.loiId, this.store.actor).subscribe(
          resp => {
            if(resp["status"]==1){
              let res = resp["data"];
             // let res = resp;
              this.fertilizerMarketingResponseStatus = res["STATUS"];
            } 
            else if(resp["status"]==403) this.router.navigate["/"];
          }
        );;
      }

      onSubmitTechnology(status){
        if(this.actor==2)status = status + 2;
        this.technology.submit = status;
        this.httpService.updateDetails('save-plant-technology', this.technology, 
        this.store.loiId, this.store.actor).subscribe(
          resp => {
            if(resp["status"]==1){
              let res = resp["data"];
              this.technologyResponseStatus = res["STATUS"];
            } else if(resp["status"]==403) this.router.navigate["/"];
          }
        );;
      }
      
     onSubmitProjectProgress(status){
      if(this.actor==2)status = status + 2;
      this.projectProgress.submit = status;
      this.httpService.updateDetails('save-project-progress', this.projectProgress, 
      this.store.loiId, this.store.actor).subscribe(
        resp => {
          if(resp["status"]==1){
            let res = resp["data"];
            this.projectProgressResponseStatus = res["STATUS"];
          } else if(resp["status"]==403) this.router.navigate["/"];
        } 
      );
    }
      
     onSubmitApprovals(status){
      if(this.actor==2)status = status + 2;
      this.approvals.submit = status;
      this.httpService.updateDetails('save-approvals', this.approvals, 
      this.store.loiId, this.store.actor).subscribe(
        (resp) => {
          if(resp["status"]==1){
            let res = resp["data"];
            this.approvalsResponseStatus = res["STATUS"];
            if(this.approvalsResponseStatus!=1)this.approvals.submit=status;
          } else if(resp["status"]==403) this.router.navigate["/"];
          
        },
        (err) => {
          this.approvalsResponseStatus = 0;
          this.approvals.submit=status;
        }
      );
    }

    onSubmitBgDetails(status){
      if(this.actor==2)status = status + 2;
      this.bgDetails.submit = status;
    
      this.httpService.updateDetails('save-bg-details', this.bgDetails,
        this.store.loiId, this.store.actor).subscribe(
        (resp) => {
          if(resp["status"]==1){
          let res = resp["data"];
            this.bgDetailsResponseStatus = res["STATUS"];
            if(this.bgDetailsResponseStatus!=1)this.bgDetails.submit=status;
          }else if(resp["status"]==403) this.router.navigate["/"];
        },
        (err) => {
          this.bgDetailsResponseStatus = 0;
          this.bgDetails.submit=status;
        }
      );
    }

    onSubmitRo(status){
      if(this.actor==2)status = status + 2;
      this.roMapping.submit=status;
    //  console.log(this.roMapping);
      this.roMapping.roArray = JSON.stringify(this.roMapping.ro);
      this.httpService.updateDetails('save-ro-mapping', this.roMapping, 
      this.store.loiId, this.store.actor).subscribe(
      
        (resp) => {
         if(resp["status"]==1){
            let res = resp["data"];
            this.roMappingResponseStatus = res["STATUS"];
         } else if(resp["status"]==403) ["/"];
        },
        (err) => {
          this.roMappingResponseStatus = 0;
        }
      );
    }
      addRo(){
        this.roMapping.ro.push(new RO());
      }

      removeRo(){
        //this.roMapping.ro.splice(-1,1);
        this.roMapping.ro.pop();
      } 
}