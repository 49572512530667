import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { LOV_TYPE } from 'app/shared/Constants';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root'
  })
export class StoreService {

  private _actor = null;
  private _omc = null;
  private _userId = null;
  private _dispName=null;
  private _usernameout=null;
  // username: any;

  get actor()
  {
    return this._actor;
  }
  set actor(a)
  {
    this._actor = a;
  }
  get userId()
  {
    return this._userId;
  }
  set userId(u)
  {
    this._userId = u;
  }
  get name()
  {
    return this._dispName;
  }
  set name(n)
  {
    this._dispName = n;
  }
  get omc()
  {
    return this._omc;
  }
  set omc(o)
  {
    this._omc = o;
  }
  get usernameout()
  {
    return this._usernameout;
  }
  set usernameout(un)
  {
    this._usernameout = un;
  }

  ros;
  //ros = [{name: 'abc', code: '000012'},{ name:'def', code: '000020'},{ name:'fmksdfj', code: '000030'}];
  approvalStatusLovs = ['Not Applied', 'Obtained', 'Rejected', 'Applied'];
  unitStatusLov = ['Work Order yet to be placed', 'Work Order Placed', 'Material Received at site', 'Material installed at site', 'Commissioned'];
  banks;
  districts;
  bankDistricts;
  plantTechnologies;
  roDistricts;

  feedstockTypes;
  /*feedstockTypes = ['Agri Residue', 'Municipal Solid Waste (MSW)', 'Molasses', 'PressMud', 'Spentwash', 'Any feedstock (depending on availability)', 'Animal Waste', 'Other'];*/

  omcs;
  /*omcs = ['IOCL', 'BPCL', 'HPCL', 'GAIL', 'IGL'];*/

  states=null;
  public loiId;
  public loiRefNo;

  loiList = null;
  grievanceCategories=null;
  grievanceSubCategories;
  
  constructor(private httpService : HttpService) { 
  }

 loadUserDetails(){
    this.httpService.getUser().subscribe(res => {
      if(res["status"]==1){
        this.actor = res["actor"];
        this.userId = res["userId"];
        this.omc = res["omc"];
      }
    });
  }


  createLovs(jsonObj: Array<any>){
    this.omcs = [];
    this.plantTechnologies = [];
    this.feedstockTypes = [];
  //  console.log("%%%%%%%%%%jsonobj%%%%%%%%%%");
  //  console.log(jsonObj);
    jsonObj.forEach( r =>
      {
        if(r["lov_id"]==LOV_TYPE.OmcLOV)
        {
          this.omcs.push(r["lov_value"]);
        } 
        else if(r["lov_id"]==LOV_TYPE.PlantTechnologyLOV)
        {
          this.plantTechnologies.push(r["lov_value"]);
        } 
        else if(r["lov_id"]==LOV_TYPE.FeedstockTypeLOV){
        this.feedstockTypes.push(r["lov_value"]);
      }
    })
  }


  loadBanks(){
   
    if(!this.banks)
          this.httpService.fetchLov(LOV_TYPE.BankLOV).subscribe((resp)=> {
            if(resp["status"]==1){
              let res = resp["data"];
           // let res = resp;
              this.banks = res["items"].map(r => r.bank_name);
              
              //this.financialClosure.financeInstitute = this.httpService.banks[0];
            }
            
          });
  }

  /*loadRos(){
    if(!this.ros){
      this.httpService.getRos(this.omc).subscribe((resp) => {
        if(resp["status"]==1){
          let res = resp["data"];
          this.ros = res["items"]
        }
      }
      );
    }
  }*/

}



