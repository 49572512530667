import { Component, OnInit } from '@angular/core';
import { StoreService } from 'app/services/store.service';

export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const ROUTES: RouteInfo[] = [
  { path: '/grievances-main',     title: 'Create',         icon:'nc-bank',       class: '' },
  { path: '/grievances-list',         title: 'List',        icon:'nc-tile-56',    class: '' }
];


@Component({
  moduleId: module.id,
  selector: 'grievance-sidebar-cmp',
  templateUrl: './grievance-sidebar.component.html',
  styleUrls: ['./grievance-sidebar.component.css']
})
export class GrievanceSidebarComponent implements OnInit {
  public menuItems: any[];
  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => 
                    (this.store.actor==2 || this.store.actor==5 || this.store.actor==3) && menuItem.path=='/grievances-main'?null:menuItem);

  }
  constructor (private store: StoreService){}

}
