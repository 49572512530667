import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Basic } from 'app/forms/models/basic';
import { HttpService } from '../services/http.service';
import { LOV_TYPE } from 'app/shared/Constants';
import { ExcelService } from 'app/services/excel.service';
import { StoreService } from 'app/services/store.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatExpansionModule} from '@angular/material/expansion';
import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatTabsModule} from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field'; 
import { filterLoi } from 'app/filterLoi.pipe';
import {MatDialog, MatDialogRef, MatDialogModule, MatDialogConfig} from '@angular/material/dialog';
import { DeleteloiComponent } from 'app/deleteloi/deleteloi.component';
import { RestoreLoiComponent } from 'app/restore-loi/restore-loi.component';
import { HighlightsComponent } from '../highlights/highlights.component';
import { CommissionedPlantsComponent } from '../commissioned-plants/commissioned-plants.component';
import { NoticeBoxComponent } from '../notice-box/notice-box.component';
import { UpdateVendorNameComponent } from '../update-vendor-name/update-vendor-name.component';


@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})

export class UserHomeComponent{

    selectedState;
    lois = [];
    inlois = [];
    addLoiResponseStatus = -1;
    selectedLoi;
    basicDetails;
    newLoi = false;
    vendors = null;
    selectedVendor;
    searchLoiText;
    titleText;
    errorMsg;
    fprList = [];
    fprLOI=false;
    mstUser;
    mstRole;
    plants;

    get actor(){
        return this.store.actor;
    }
    get states(){
        return this.store.states;
    }
    get omcs(){
        return this.store.omcs;
    }
    get districts(){
        return this.store.districts;
    }

    get usernameout(){
        return this.store.usernameout;
    }

    constructor(
        private router: Router, 
        private httpService :HttpService,
        private excelService: ExcelService,
        public dialog: MatDialog,
        private store: StoreService)
        {
        this.titleText= "LOIs Issued by " + this.store.omc;
        if(store.actor==4)this.titleText = '';
        
        if(!this.store.states)       
        this.httpService.fetchLov(LOV_TYPE.StateLOV).subscribe((res)=>{
            if(res["status"]==1){
                let resp = res["data"];
                this.store.states = resp["items"];
                this.selectedState = this.store.states[0];
                this.fetchDistricts(this.selectedState.state_id);
            }
        }
        );

        {
            this.httpService.getFPR(this.store.omc).subscribe((resp) =>{
                this.fprList = resp["items"];
            });
        }
    }
    ngOnInit(){
        if(!this.lois || this.lois.length==0) this.fetchLois(1);
        if(!this.inlois || this.inlois.length==0) this.fetchLois(0);
    }
    goToReport(){
        this.router.navigate(['dashboard']);
    }

    editHighlights(){
        //this.router.navigate(['highlights']);
        const dialogRef =  this.dialog.open(HighlightsComponent, {
            width: '650px',
            height: '250px',
          });
    }

    addCommissionedPlants(){
        const dialogRef =  this.dialog.open(CommissionedPlantsComponent, {
            width: '850px',
            height: '750px',
          });

        //   this.httpService.getCommissionedPlants().subscribe(resp => {
        //      if(resp["status"]==1){
        //          let res = resp["data"];
        //          this.plants = res["items"];
        //          console.log("plants are ",this.plants);
        //      }
        //  });
    }

    addNotice(){
        const dialogRef =  this.dialog.open(NoticeBoxComponent, {
            width: '650px',
            height: '450px',
          });
    }


    stateSelected(){
        this.basicDetails.state = this.selectedState.state;
        this.fetchDistricts(this.selectedState.state_id);
        this.basicDetails.district = this.districts[0];
    }
    
    goToForm(loi)
    {
        this.store.loiRefNo = loi.loi_reference_number;
        this.store.loiId = loi.loi_system_id;
        this.router.navigate(['/form']);
    }

    
    vendorSelected(){
        this.basicDetails.vendorId = this.selectedVendor.user_id;
        this.basicDetails.orgName = this.selectedVendor.display_name;
        this.basicDetails.mobile = this.selectedVendor.username;
    }
    actionChanged(){
        if(this.basicDetails.createVendor==0){
            this.selectedVendor = this.vendors[0];
            this.vendorSelected();
        }else{
            this.basicDetails.mobile = null;
            this.basicDetails.orgName = null;
        }

    }
    exportData(){
        this.httpService.download(this.store.omc,this.store.actor).subscribe(resp => 
            {
                if(resp["status"]==1){
                    let res = resp["data"];
                    this.excelService.exportAsExcelFile(res["items"], 'LOIData');
                } else if(resp["status"]==1){
                    this.router.navigate(["/"]);
                }
            }
        );
    }
    fetchDistricts(stateId){
        this.httpService.fetchLov(LOV_TYPE.DistrictLOV, stateId).subscribe((res)=>{
            if(res["status"]==1){
                let resp = res["data"];
                this.store.districts = resp["items"].map(r => r.district);
            }
        });
    }
    fetchLois(status){
        console.log(status)
        if(this.store.actor==3)
        {
            this.httpService.getLois(this.store.userId,2,status).subscribe((resp) =>{
                if(resp["status"]==1){
                    let res = resp["data"];
                    if(status==1)
                    {this.lois = res["items"];}
                    else
                    {this.inlois = res["items"];}
                    this.store.loiList = res["items"]; 
                }
            });
        }
        else{
            this.httpService.getLois(this.store.userId,this.store.actor, status).subscribe((resp) =>{
                if(resp["status"]==1){
                    let res = resp["data"];
                    if(status==1)
                    {this.lois = res["items"];}
                    else
                    {this.inlois = res["items"];}
                  this.store.loiList = res["items"];
                }
            });
        }
    }

    addLoi(){
        this.basicDetails.mobile = this.basicDetails.mobile.toString();
        if((this.basicDetails.mobile).length!=10)
        {
            this.addLoiResponseStatus=0;
            this.errorMsg="Mobile Number must have exactly 10 digits";
        }
        this.httpService.addLoi(this.basicDetails).subscribe(
            (res) => {
               
                if(res["status"]==1){
                    let resp = res["data"];
                    if(resp["STATUS"]==0)
                    {
                        this.addLoiResponseStatus=0;
                        this.errorMsg="This Mobile Number Already Exists.";
                    }
                    else{
                        this.addLoiResponseStatus = 1;
                        if(this.addLoiResponseStatus==1){
                        this.basicDetails = null;
                        this.newLoi = false;
                        this.fetchLois(1);
                    }
                    }
                }
            }
        );
    }

    addNewLoi(){
        if(!this.vendors){
            this.httpService.getVendors().subscribe(resp => {
                if(resp["status"]==1){
                    let res = resp["data"];
                    this.vendors = res["items"];
                }
            });
        }

        this.basicDetails = new Basic();

        this.selectedState = this.states[0];
      
        this.basicDetails.state = this.selectedState.state;
    
        this.basicDetails.omc = this.store.omc;
   
        this.basicDetails.district = this.districts[0];
       
        this.newLoi = true;
       
    } 
    deleteLoi(loi)
    {
        this.httpService.deleteLoi(0,1).subscribe((resp) =>
        {
            if(resp["status"]==1){
                this.fetchLois(1)
            }
        })
    }

    openDialog(loi_system_id, loi_reference_number): void {
        const dialogRef =  this.dialog.open(DeleteloiComponent, {
          data:{loi_system_id:loi_system_id, loi_reference_number:loi_reference_number}
        });

        dialogRef.afterClosed().subscribe(result => {
                this.fetchLois(1);
                this.fetchLois(0);
          });
      }

      openDialogRestore(loi_system_id, loi_reference_number): void {
        const dialogRef =  this.dialog.open(RestoreLoiComponent, {
          data:{loi_system_id:loi_system_id, loi_reference_number:loi_reference_number}      
        });

        dialogRef.afterClosed().subscribe(result => {
                this.fetchLois(1);
                this.fetchLois(0);
          });
      }

      sortVendors(criteria: string) {
        if (criteria === 'nameAsc') {
          this.vendors.sort((a, b) => a.display_name.localeCompare(b.display_name));
        } else if (criteria === 'nameDesc') {
          this.vendors.sort((a, b) => b.display_name.localeCompare(a.display_name));
        }
      }

      updateVendor(){
        const dialogRef =  this.dialog.open(UpdateVendorNameComponent, {
            width: '650px',
            height: '265px',
          });
      }
}