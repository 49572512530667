import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpService } from '../services/http.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-restore-loi',
  templateUrl: './restore-loi.component.html',
  styleUrls: ['./restore-loi.component.css']
})
export class RestoreLoiComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RestoreLoiComponent>,
    private httpService :HttpService,
     @Inject(MAT_DIALOG_DATA) public data,
     private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  yes(){
    this.httpService.deleteLoi(1,this.data.loi_system_id).subscribe((resp) =>
        {
            if(resp["status"]==1){
                this.openSnackBar("LOI Status Restored","Ok");
                this.dialogRef.close();
            }

        })

  }

  openSnackBar(message, action) {
    this._snackBar.open(message,action, {
      duration: 5000,
    });
  }


  close(){
    this.dialogRef.close();
  }

}
