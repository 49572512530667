export class Technology {
    public loiId: string;
    public plantTechnology: string;
    public otherTechnology: string;
    public submit: number;
    public actor: number;

    constructor(jsonObj=null){
        if(jsonObj){
            this.loiId = jsonObj['loi_status_id'];
            this.plantTechnology = jsonObj['plant_technology'];
            this.otherTechnology = jsonObj['other_technology'];
            this.submit = jsonObj['form_status'];
            this.actor = jsonObj['record_role'];
        } else this.submit = 0;
      }
    }