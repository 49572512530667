import { Component } from "@angular/core";
import { LoaderService } from './loader.service';

@Component({
    selector : 'app-loader',
    templateUrl : './loader.component.html',
    styleUrls: ['./loader.component.css']
})
export class LoaderComponent {

    color = 'primary';
    mode = 'indeterminate';
    value = 50;

    isLoaded() {

        return this.loaderService.isLoaded;
    }

    constructor(private loaderService: LoaderService){
    }

}