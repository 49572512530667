
export class RoMapping {
    public loiId: string;
   
    public submit: number;
    public actor: number;
    public ro = [];
    public roArray: string;
    /*public roName = [];
    public roCode = [];
    public compressorStatus = [];
    public dispensingUnitStatus = [];
    public cascadeStatus = [];
    public pesoApprovalStatus = [];
    public stampingStatus = [];
    public saleCommencementDate = [];*/
}


export class RO{
    public roName: string;
    public roCode: string;
    public compressorStatus: string;
    public dispensingUnitStatus: string;
    public cascadeStatus: string;
    public pesoApprovalStatus: string;
    public stampingStatus: string;
    public saleCommencementDate: string;
}