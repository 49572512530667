import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'report/http.service';

@Component({
  selector: 'app-participate',
  templateUrl: './participate.component.html',
  styleUrls: ['./participate.component.css']
})
export class ParticipateComponent implements OnInit {

  constructor(private httpService :HttpService, private router: Router,
    ) { 

    
    
  
  }

  ngOnInit(): void {
  }


  faq(){
    this.router.navigateByUrl("/faq");
  }

  satatprocess(){
    this.router.navigateByUrl("/processAtGlance");
  }

  scheme(){
    this.router.navigateByUrl("/scheme");
  }



}
