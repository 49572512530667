import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ToastrModule } from "ngx-toastr";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NavbarModule} from './navbar/navbar.module';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { HttpService } from './services/http.service';
import { ExcelService } from './services/excel.service';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { LoaderComponent } from './loader/loader.component';
import { LoaderService } from './loader/loader.service';
import { LoaderInterceptor } from './loader/loader.interceptor';
import { FormComponent } from './forms/form/form.component';
import { FormMainComponent } from './forms/form-main.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { UserHomeComponent } from './user-home/user.component';
import { FormStatusComponent } from './forms/form-status/form-status.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { HomeComponent } from "./home/home.component";
import { DatePipe } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import {IvyCarouselModule} from 'angular-responsive-carousel';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { StoreService } from "./services/store.service";
import {ReportModule} from '../report/report.module';
import { AdminComponent } from './admin/admin.component';
import { GrievanceSidebarModule } from './grievances/grievance-sidebar/grievance-sidebar.module';
import { GrievancesComponent } from './grievances/grievances.component';
import { GrievanceSubmitResponseComponent } from './grievances/grievance-submit-response/grievance-submit-response.component';
import { GrievancesMainComponent } from './grievances/grievances-main/grievances-main.component';
import { GrievanceListComponent } from "./grievances/grievance-list/grievance-list.component";
import {MatTabsModule} from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon'; 
import {MatDialog, MatDialogRef, MatDialogModule} from '@angular/material/dialog';
import { DeleteloiComponent } from './deleteloi/deleteloi.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { RestoreLoiComponent } from './restore-loi/restore-loi.component';
import { NewhomeComponent } from './newhome/newhome.component';
import { ParticipateComponent } from './participate/participate.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { DevelopmentsComponent } from './developments/developments.component';
import { MainpageComponent } from './mainpage/mainpage.component';

import { NgImageSliderModule } from 'ng-image-slider';
import { PagesCbgComponent } from './pages-cbg/pages-cbg.component';
import { PagesAboutComponent } from './pages-about/pages-about.component';
import { PagesSchemeComponent } from './pages-scheme/pages-scheme.component';
import { PagesFertilizersComponent } from './pages-fertilizers/pages-fertilizers.component';
import { TechnologyComponent } from './technology/technology.component';
import { ModulesComponent } from './modules/modules.component';
import { PagesComissionedPlantsComponent } from './pages-comissioned-plants/pages-comissioned-plants.component';
import { PagesParticipationComponent } from './pages-participation/pages-participation.component';
import { PagesSatatProcessComponent } from './pages-satat-process/pages-satat-process.component';
import { FaqComponent } from './faq/faq.component';
import { filterLoi } from "./filterLoi.pipe";
import { DemoComponent } from './demo/demo.component';
import { HighlightsComponent } from './highlights/highlights.component';
import { CommissionedPlantsComponent } from './commissioned-plants/commissioned-plants.component';
import { UploadPlantsComponent } from './upload-plants/upload-plants.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NoticeBoxComponent } from './notice-box/notice-box.component';
import { UpdateVendorNameComponent } from './update-vendor-name/update-vendor-name.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoaderComponent,
    FormComponent,
    FormMainComponent,
    UserHomeComponent,
    FormStatusComponent,
    HomeComponent,
    AdminComponent,
    GrievancesComponent,
    GrievanceSubmitResponseComponent,
    GrievancesMainComponent,
    GrievanceListComponent,
    DeleteloiComponent,
    RestoreLoiComponent,
    NewhomeComponent,
    ParticipateComponent,
    ContactComponent,
    FooterComponent,
    DevelopmentsComponent,
    MainpageComponent,
    PagesCbgComponent,
    PagesAboutComponent,
    PagesSchemeComponent,
    PagesFertilizersComponent,
    TechnologyComponent,
    ModulesComponent,
    PagesComissionedPlantsComponent,
    PagesParticipationComponent,
    PagesSatatProcessComponent,
    FaqComponent,
    filterLoi,
    DemoComponent,
    HighlightsComponent,
    CommissionedPlantsComponent,
    UploadPlantsComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    NoticeBoxComponent,
    UpdateVendorNameComponent
  ],
  imports: [
    BrowserAnimationsModule,
    NgImageSliderModule,FormsModule,
    RouterModule.forRoot(AppRoutes,{
      useHash: true,
      scrollOffset: [0, 64],
      anchorScrolling: "enabled"
    }),
    HttpClientModule,
    NavbarModule,
    ToastrModule.forRoot(),
    FormsModule,
    NgSelectModule,
    NgxSpinnerModule,
    NgbModule,
    // IvyCarouselModule,
    Ng2SearchPipeModule,
    ReportModule,
    GrievanceSidebarModule,
    BrowserModule,
    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatSnackBarModule
  ],
  providers: [
    HttpService, 
    LoaderService, 
    ExcelService, { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }, 
    DatePipe,
    StoreService
  ],
  bootstrap: [AppComponent],
  entryComponents: [DeleteloiComponent]
})
export class AppModule { }

    