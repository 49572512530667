import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Location} from '@angular/common';
import { HttpService } from 'app/services/http.service';
import { StoreService } from 'app/services/store.service';
import {MatButtonModule} from '@angular/material/button';
import {MatDialog, MatDialogRef, MatDialogModule, MatDialogConfig} from '@angular/material/dialog';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';

@Component({
    moduleId: module.id,
    selector: 'navbar-cmp',
    templateUrl: 'navbar.component.html',
    styleUrls: ['navbar.component.scss']
})

export class NavbarComponent{
  @Input() title:string;
    private listTitles: any[];
    location: Location;
    private sidebarVisible: boolean;
    public loginOmc;
    public loginName;

    public isCollapsed = true;
    @ViewChild("navbar-cmp", {static: false}) button;

    constructor(location:Location,  
      private httpService : HttpService, private store: StoreService, private router: Router, public dialog: MatDialog ) {
        this.location = location;
        this.sidebarVisible = false;
        this.loginOmc = this.store.omc;
        this.loginName = this.store.name;
        
        
    }

      logOut(){
        this.httpService.logout();
      }

      changePassword(){
        const dialogRef =  this.dialog.open(ResetPasswordComponent, {
          width: '600px',
          height: '700px',
        });
      }

}
