import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'report/http.service';


@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-main.component.html',
  styleUrls: ['./dashboard-main.component.scss']
})
export class DashboardMainComponent implements OnInit {

    constructor(private httpService : HttpService,
        private router : Router){
       // if(!httpService.sessionUser) this.router.navigate(['']);
    }

  ngOnInit() { }
}
