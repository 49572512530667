import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { StoreService } from 'app/services/store.service';
import { HttpService } from '../services/http.service';


export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    //class: string;
  }
  
  export const ROUTES: RouteInfo[] =
[
    { path: 'basicForm',     title: 'Basic Details',         icon:'nc-bank'},
    { path: 'landForm',     title: 'Land Finalization',         icon:'nc-bank'},
    { path: 'bgDetailsForm',     title: 'Bank Guarantee Details',         icon:'nc-bank'},
    { path: 'financialClosureForm',     title: 'Financial Closure',         icon:'nc-bank'},
    { path: 'feedstockForm',     title: 'Feedstock Details',         icon:'nc-bank'},
    { path: 'fertilizerMarketingForm',     title: 'Fertilizer Marketing',         icon:'nc-bank'},
    { path: 'technologyForm',     title: 'Technology',         icon:'nc-bank'},
    { path: 'projectProgressForm',     title: 'Project Progress',         icon:'nc-bank'},
    { path: 'approvalsForm',     title: 'Statutory Approvals',         icon:'nc-bank'},
    { path: 'roMappingForm', title: 'RO Mapping',         icon:'nc-bank'}
    //{ path: '/table',         title: 'Table List',        icon:'nc-tile-56',    class: '' }
  ];

@Component({
    selector: 'app-form',
    templateUrl: './form-main.component.html',
    styleUrls: ['./form-main.component.scss']
})

export class FormMainComponent{

    public menuItems: any[];

    scrollToSection(section){
      //console.log("*******************************");
      //console.log(section);
      let el:HTMLElement = document.getElementById(section);
      const offset = 70;
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = el.getBoundingClientRect().top;
      const elementPosition = elementRect - bodyRect;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
      });
   
     // el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
      //this.router.navigate(['/'], {fragment: section});
  }

    constructor(
        private store: StoreService, 
        private httpService: HttpService,
        private router: Router){
        if(store.loiId==null){
            this.router.navigate(["/home"]);
        }
        //this.store.loadUserDetails();
    }

    ngOnInit(){
        this.menuItems = ROUTES.filter(menuItem => 
            {
                if((this.store.actor==1 && menuItem.path!='roMappingForm')||this.store.actor==2)
                { return menuItem;}});
    }
    
}