import { JsonProperty } from "json2typescript";

export class Feedstock {

    //@JsonProperty('loi_system_id')
    public loiId: string;
    //@JsonProperty('primary_feedstock_type')
    public primaryFeedstockType: string;
    //@JsonProperty('other_primary_feedstock_type')
    public otherPrimaryFeedstockType: string;
    //@JsonProperty('secondary_feedstock_type')
    public secondaryFeedstockType: string;
    //@JsonProperty('other_secondary_feedstock_type')
    public otherSecondaryFeedstockType: string;
    //@JsonProperty('agreement_for_feedstock_supply')
    public feedstockSupply: string;
    //@JsonProperty('any_constraint')
    public feedstockIssue: string;
    //@JsonProperty('constraint_reason')
    public feedstockIssueDetails: string;
    //@JsonProperty('form_status')
    public submit: number;
    //@JsonProperty('record_role')
    public actor: number;

    constructor(jsonObj=null){
        if(jsonObj){
            this.loiId = jsonObj['loi_system_id'];
            this.primaryFeedstockType = jsonObj['primary_feedstock_type'];
            this.otherPrimaryFeedstockType = jsonObj['other_primary_feedstock_type'];
            this.secondaryFeedstockType= jsonObj['secondary_feedstock_type'];
            this.otherPrimaryFeedstockType = jsonObj['other_secondary_feedstock_type'];
            this.feedstockSupply = jsonObj['agreement_for_feedstock_supply'];
            this.feedstockIssue = jsonObj['any_constraint'];
            this.feedstockIssueDetails = jsonObj['constraint_reason'];
            this.submit = jsonObj['form_status'];
            this.actor = jsonObj['record_role'];
        } else this.submit = 0;
        
    }

}