import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'report/http.service';

@Component({
  selector: 'app-grievances-main',
  templateUrl: './grievances-main.component.html',
  styleUrls: ['./grievances-main.component.css']
})
export class GrievancesMainComponent implements OnInit {

  constructor(private httpService : HttpService,
    private router : Router){
   // if(!httpService.sessionUser) this.router.navigate(['']);
  }

  ngOnInit(): void {
  }

}
