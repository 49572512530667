import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StoreService } from 'app/services/store.service';
import { HttpService } from '../services/http.service';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import {MatDialog, MatDialogRef, MatDialogModule, MatDialogConfig} from '@angular/material/dialog';
import { GlobalService } from 'app/services/globalService';
import { ForgotPasswordComponent } from 'app/forgot-password/forgot-password.component';
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})


export class HomeComponent implements OnInit {

  public username;
  public password;
  public isValid = true;
  public expiry;
  public expiryFormatted: string;
  images = [{"path" : 'assets/images/slide1.png'}, {"path" :'assets/images/banner.png'}];

  constructor(
    private httpService : HttpService,
    private router: Router,
    private store: StoreService,
    public dialog: MatDialog,
    public gdata:GlobalService,
    private datePipe: DatePipe) { 
    }
  ngOnInit(): void {

    const currentDate = new Date();
    //this.expiry = new Timestamp<number>(new Date().getTime(), 0);
    //this.expiryFormatted = this.datePipe.transform(currentDate, 'dd-MMM-yy hh.mm.ss.SSSSSSSSS a');
    //this.expiryFormatted = this.datePipe.transform(this.expiry.timestamp, 'dd-MMM-yy hh.mm.ss.SSSSSSSSS a');

    
  }
  reset = false;
  otp;
  savedOTP;
  otpSuccess = null;
  openLogin = true;
  openForgotPass = false;
  openCreatePass = false;
  otpSent: boolean = false;
  validUsername: boolean = true;
  otpButtonDisabled: boolean = false;

  forgotPassword(){
    this.reset = true;
  }

  clearUsernameError() {
    this.validUsername = true; // Assuming this resets the error message
  }

  sendOtp(){
    this.httpService.verifyUsername(this.username).subscribe(
      res=> {
        let resp = res["data"];
        console.log("whole response of verify username is ",res);
        if(resp["status"]==1){
          this.validUsername =true;
          // const current = new Date();
          // const timestamp = current.getTime();
          // console.log("current time is ",expiry);
          this.httpService.saveOtp('123456', this.username).subscribe(resp =>
            {
              //console.log("expiry value is ",this.expiry);
              let res = resp["data"];
              // let res = resp["data"];
              console.log("whole response of send otp is ",res);
              //this.gdata.openErrorPrompt(res["status"],"OK")
              if(res["status"]==1){
                this.otpSent = true;
                this.otpButtonDisabled = true;
                 setTimeout(() => {
                this.otpButtonDisabled = false; // Enable the button after 2 minutes
                    }, 2 * 60 * 1000); 
              setTimeout(() => {
                  this.otpSent = false;
              }, 2000);
              }
            });
        }
        else{
          setTimeout(() => {
            this.validUsername = false;
        }, 2000);
        }
      });
    }
     
 
    // this.httpService.generateOtp(this.username).subscribe(resp => console.log(resp));

    // this.httpService.generateOtp(this.username).subscribe(resp =>
    //   {
    //     // let res = resp["data"];
    //     console.log("whole response is ",resp);
    //   });
    

  forgotPass(){
    this.username=null;
    this.openForgotPass=true;
    this.openLogin = false;
    this.openCreatePass = false;
  }
  
  // verifyOtp(){
  //   this.httpService.validateOtp(this.otp, this.username).subscribe(resp =>
  //     {
  //       let res = resp["data"];
  //       this.otpSuccess = res["IsAuthenticated"]=='Y';
  //       if(this.otpSuccess) {
  //         this.openForgotPass=false;
  //         this.openLogin = false;
  //         this.openCreatePass = true;
  //       }
  //     });
  // }

  verifyOtp(){
    this.httpService.validateOtp(this.otp, this.username).subscribe(resp =>
      {
        let res = resp["data"];
        console.log("whole response of verify otp is ",res);
        if(res["status"]==1)
        { this.gdata.openErrorPrompt("OTP verified Successfully","OK"); 
         const dialogRef =  this.dialog.open(ForgotPasswordComponent, {
          width: '600px',
          height: '700px',
        });}
        else{this.gdata.openErrorPrompt("OTP not matching","OK");}
      });
  }

  createPassword(){
    this.httpService.createPassword(this.username, this.password).subscribe(
      res=> {
        let resp = res["data"];
 
        if(resp["status"]==1){
          this.openForgotPass=false;
          this.openLogin = true;
          this.openCreatePass = false;
        }
      }
    );
  }
  
  login() {
    console.log("current password is ",this.password);
    sessionStorage.setItem('password', JSON.stringify( this.password));
    let resp = this.httpService.authenticate(this.username, this.password).subscribe(
      (resp) => {
        let res=resp;
        console.log("whole response is ",res);
        
        if(res["status"]==0) 
          this.isValid = false; 
        else {
          if(res["status"]==1){
            let data = res["data"];
            console.log("whole data is ",data);
            this.store.actor = data["USER_ROLE"];
            this.store.omc = data["OMC"];
            this.store.userId = data["USER_ID"];
            this.store.name = data["DISPLAY_NAME"];
            this.store.usernameout = data["USERNAMEOUT"];
            sessionStorage.setItem('usernameout', JSON.stringify( this.store.usernameout));
            this.isValid = true;
            if(data["USER_ROLE"]==3)
            this.router.navigate(['/admin']);
            else
            this.router.navigate(['/home']);
          } else this.isValid = false;
      }
    },
      (err) => {this.isValid = false;}
    );;
    } 
}
