import { Routes } from '@angular/router';
import { DashboardMainComponent } from './dashboard-main/dashboard-main.component';
import {DashboardDetailComponent} from './dashboard-detail/dashboard-detail.component';

export const ReportRoutes: Routes = [
  {
    path: 'dashboard',
    component: DashboardMainComponent
  },
  {
    path: 'detail-report',
    component: DashboardDetailComponent
  }
]