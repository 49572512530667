import { Component, OnInit } from '@angular/core';
import { HttpService } from '../services/http.service';
import { HttpClient } from '@angular/common/http';
import { StoreService } from 'app/services/store.service';
import { DomSanitizer, SafeUrl  } from '@angular/platform-browser';
import { timer } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { FormsModule, NgForm } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-notice-box',
  templateUrl: './notice-box.component.html',
  styleUrls: ['./notice-box.component.css']
})
export class NoticeBoxComponent implements OnInit {
  //sanitizedUrl: any;
  fileContent: string;
  noticeContent: string;
  validTill: string;
  successMsg;
  img_err: string = ""; 
  hasNewFileSelected: boolean = false;
  errorMsg;
  showMsg: boolean= false;
  fileSizeError: string | null = null;
  minDate: string;
  fileName: string;

  constructor(
    private httpService :HttpService,
    private store: StoreService,
    private sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<NoticeBoxComponent>,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {

    this.minDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  }

  // this.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + reader.result.toString().split(',')[1]);     
  // this.photob641 = reader.result.toString().split(',')[1];


  onFileSelected(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      this.fileName = file.name;
      this.hasNewFileSelected = true;
      console.log("File name is :",this.fileName);
      reader.readAsDataURL(file);
      reader.onload = () => {       
        this.fileContent = reader.result.toString().split(',')[1];;
        console.log("Content of File :",this.fileContent);
      };
    }
  }

  save(form: NgForm){
    // if (!this.fileContent && !this.hasNewFileSelected) {
    //   this.img_err = "Please Upload file";
    //   return;
    // }
    if (form.valid) {
      this.httpService.uploadNoticeContent(this.noticeContent,this.validTill,this.fileContent,this.fileName).subscribe((resp) =>{
        if(resp["status"]==1){
          console.log('Form Valid:', form.valid);
         
            let res = resp["data"];
            console.log("data is ",res);
          
            this.successMsg = res["OUTPUT_MSG"];
            this.errorMsg = res["OUTPUT_MSG"];
  
            this.showMsg = true;
            timer(1000).subscribe(() => {
              this.showMsg = false;
              this.dialogRef.close();
            });
        }
    });
}
  }

  close(){
    this.dialogRef.close();
  }

}
