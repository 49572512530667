import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { LOV_TYPE } from 'app/shared/Constants';
import { StoreService } from './store.service';
import { environment } from 'environments/environment.prod';

@Injectable({
    providedIn: 'root'
  })
export class HttpService {
    url=environment.apiUrl;
    smsUrl = environment.sendSMSUrl; 
   //url= "/satat/API";

    //private baseUrl = 'https://fnogwlazcl2v6gv-crmatp.adb.ap-mumbai-1.oraclecloudapps.com/ords/satat_prod/satat/';
    //private baseUrl = 'https://fnogwlazcl2v6gv-crmatp.adb.ap-mumbai-1.oraclecloudapps.com/ords/cois_ws/satat/';
    httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin':'*',
        'Content-Type':  'application/json',
        'Accept':  'application/json'
      })
    };
    constructor(private http: HttpClient, private router: Router) {
    }

    // , private store: StoreService

    /*public updateDetails(path, _data, loiId, actor): Observable<any>
    {
      let url = this.baseUrl + path;
      _data["loiId"] = loiId;
      _data["actor"] = actor;
      return this.http.post(url,JSON.stringify(_data),this.httpOptions);
    } */
     
    public updateDetails(path, _data, loiId, actor): Observable<any>{
      _data["loiId"] = loiId;
      _data["actor"] = "_actor_";
      let data = {
        "service" : path,
        "data" : _data
      }
      return this.http.post(this.url ,JSON.stringify(data, (k,v)=> {if(v!=null)return v;}));
    }


    /*public addLoi(_data, userId)
    {
      let url = this.baseUrl + 'Satat_Form_CreateLoi';
      _data["fprId"] = userId;
      return this.http.post(url, JSON.stringify(_data),this.httpOptions);
    }*/
    public addLoi(_data){
      
      _data["fprId"] = "_user_id_";
      let data = {
        "service" : 'create-loi',
        "data" : _data
      }
      return this.http.post(this.url, JSON.stringify(data));
    }

    public saveGrievance(path,_data){
      let data = {
        "service" : path,
        "data" : _data
      }
      return this.http.post(this.url, JSON.stringify(data));
    }

    public getGrievanceList(path,_data){
      let data = {
        "service": path,
        "data" : _data
      }
      return this.http.post(this.url, JSON.stringify(data));
    }

    public getGrievanceDetails(path,_data){
      let data = {
        "service": path,
        "data" : _data
      }
      return this.http.post(this.url, JSON.stringify(data));
    }

    public saveGrievanceResponse(path,_data){
      let data = {
        "service": path,
        "data" : _data
      }
      return this.http.post(this.url, JSON.stringify(data));
    }

    /*public getDetails(path, loiId): Observable<any>
    {
      let url = this.baseUrl + path + '/' + loiId;
      return this.http.get(url,this.httpOptions);
    } */
    public getDetails(path, loiId): Observable<any>{
      let data = {
        "service" : path,
        "data" : { 'loiId': loiId}
      }
      return this.http.post(this.url,JSON.stringify(data));
    }

    /*public authenticate(username, password){
      let data = {"username" : username, "passcode" : password}
      return this.http.post(this.baseUrl + 'login', JSON.stringify(data), this.httpOptions);
    }*/

    
    
    public authenticate(username, password){
     // let url = "/satat/login";
     //let url = "http://localhost:8080/satat_uat/login";
     let url = environment.loginUrl;
      let data = {
        "service" : "login",
        "data" : {
          "username" : username, 
          "passcode" : password
        }
      }
      return this.http.post(url, JSON.stringify(data));
    }

    /*public getLois(userId, actor)
    {
      return this.http.get(this.baseUrl + 'Satat_Form_GetLoi/' + actor + '/' + userId,this.httpOptions);
    }*/

    public mainStatistics()
    {
      let data = {
        "service" : "mainPageData"
      }
      return this.http.post(this.url,data);
    }

    

    // public totalPlantComissioned()
    // {
    //   let data = {
    //     "service" : "plantComissioned"
    //   }

    //   return this.http.post(this.url,data);
    // }

    public getLois(userId,actor,loiStatus){
      let data = {
        "service" : "get-loi",
        "data" : {
          
          "actor" : actor,
          "xloiStatus" : loiStatus,
          "userId" : userId, 
        }
      }
      return this.http.post(this.url, JSON.stringify(data));
    }

    public saveHighlights(total_loi,plants_commissioned,gas_sales){
      let data = {
        "service" : "Satat_Upd_Main_Data",
        "data" : {          
          "totalloi" : total_loi,
          "plantscommissioned" : plants_commissioned,
          "gassales" : gas_sales, 
        }
      }
      return this.http.post(this.url, JSON.stringify(data));
    }

    public uploadCommissionedPlants(plantname,location,capacity,feedstock,loiissue,plantimg,actionby){
      let data = {
        "service" : "Satat_Upload_Commissioned_Plants",
        "data" : {
          "plantname" : plantname,
          "location" : location,
          "capacity" : capacity,
          "feedstock" : feedstock, 
          "loiissue" : loiissue,
          "plantimg" : plantimg,
          "actionby" : actionby, 
        }
      }
      console.log("plant action by ",actionby);
      return this.http.post(this.url, JSON.stringify(data));
    }

    public getCommissionedPlants(){
      let data = {
        "service" : "Satat_Get_Commissioned_Plants",
      }
      return this.http.post(this.url, JSON.stringify(data));
    }

    public deleteLoi(flag,loiId)
    {
      let data = {
        "service" : "update-loi",
        "data":{
          "loiId" : loiId,
          "activeInactive" : flag,
        }
      }
      return this.http.post(this.url, JSON.stringify(data));
    }

    /*public getRos(omc){
      return this.http.get(this.baseUrl+ 'Get_RO_Master/' + omc,
       this.httpOptions);
    }*/
    public getRos(_omc,_state,_district){
      let data = {
        "service" : "get-ros",
        "data" : {
          "omc" : _omc,
          "stateRo":_state,
          "district":_district
        }
      }
      return this.http.post(this.url, JSON.stringify(data));
    }

    /*public getVendors(omc){
      return this.http.get(this.baseUrl + 'Satat_Form_FetchVendor' + '/' + omc, this.httpOptions);
    }*/
    public getVendors(){
      let data = {
        "service" : "get-vendors",
        "data" : {
          "omc" : "_omc_"
        }
      }
      return this.http.post(this.url, JSON.stringify(data));
    }

    /*public logout(){
      // this.store.actor = null;
       this.router.navigate(['']);
    }*/
    public logout(){
      //let url = "/satat/login";
      //let url = "http://localhost:8080/satat_uat/login";
      let url = environment.loginUrl;
      let data = {
        "service" : "logout"
      }
      return this.http.post(url, JSON.stringify(data)).subscribe(res => {
        if(res["status"]==1) 
        this.router.navigate(['']);
      });
    }
    
    public getFPR(_omc){
      let data = {
        "service" : "get-fpr-details",
        "data" : {
          "omc" : _omc
        }
      }
      return this.http.post(this.url, JSON.stringify(data));
    }


    /*public fetchLov(lovType: LOV_TYPE, param = null){
      if(lovType==LOV_TYPE.StateLOV){
        return this.http.get(this.baseUrl + 'lov-state');
      } else if(lovType==LOV_TYPE.BankLOV){
        return this.http.get(this.baseUrl + 'lov-bank');
      } else if(lovType==LOV_TYPE.DistrictLOV){
        return this.http.get(this.baseUrl + 'lov-state/'+param);
      } else return this.http.get(this.baseUrl + 'lov');
    }*/
    
    public fetchLov(lovType: LOV_TYPE, param = null)
    {
      let service = ""; 
      let _data = {};
      if(lovType==LOV_TYPE.StateLOV)
      {
        service = 'lov-state';
      } 
      else if(lovType==LOV_TYPE.BankLOV)
      {
        service = 'lov-bank';
      } 
      else if(lovType==LOV_TYPE.DistrictLOV)
      {
        service = "lov-district";
        _data = {"stateId" : param};
      }else if(lovType==LOV_TYPE.GrievanceCatLOV)
      {
         service  = 'lov-grievanceCat';
      }else if(lovType==LOV_TYPE.GrievanceSubCatLOV)
      {
         service = 'lov-grievanceSubCat';
         _data = {"grievanceCatId" : param};
      } 
      else service = 'lov';

      let data = {
        "service" : service,
        "data" : _data
      }
      return this.http.post(this.url, JSON.stringify(data));
    }

    getUser(){
      //let url = "http://localhost:8080/satat_uat/login";
      //let url = "/satat/login";
      let url = environment.loginUrl;
      let data = {
        "service" : "user"
      }
      return this.http.post(url, JSON.stringify(data));
    }

    /*download(omc) {
      if(omc)
      return this.http.get(this.baseUrl + 'Excel_Master_Download/' + omc, this.httpOptions);
      else return this.http.get(this.baseUrl + 'Excel_Master_Download', this.httpOptions);
    }*/
  download(omc, actor) {
    let data = {};
    if(omc) {
      if(actor==4){
        data = {
          "service" : "download"
        }
      }else{
        data = {
          "service" : "download",
          "data" : {
            "omc" : "_omc_"
          }
        }
      }      
    } else {
      data = {
        "service" : "download"
      }
    }
    return this.http.post(this.url, JSON.stringify(data));
}

uploadFiles(formData){
  //let url = "http://localhost:8080/satat_uat/Upload";
  //let url = "/satat/Upload";
  let url = environment.uploadUrl;
  this.http.post(url, formData).subscribe( 
    (res) => { 
      console.log(res);
    }
 )
}

public createPassword(username, password): Observable<any>{
  let data = {
    "service" : "UpdatePassword",
    "data" : {
      "username" : username,
      "passcode" : password,
    }
  };
   return this.http.post(this.url,JSON.stringify(data));
 }

 /*public validateOtp(otp, username): Observable<any>{
     let url = "https://api.indianoil.in/CRMNexPRDExt/OTPAuthentication/OTPAuthenticationRest/ValidateOTP";
     let data = {
       "TrackingId" : username,
       "MobileNumber" : username,
       "Source" : "Mobile",
       "OTP" : otp
     };
     let _httpOptions = this.httpOptions;
      _httpOptions.headers = new HttpHeaders({'Authorization' : btoa('mobility_user:M0b1l1ty@I0cl!')});
     return this.http.post(url,JSON.stringify(data),this.httpOptions);
   }*/

//  public validateOtp(otp, username): Observable<any>{
//   let data = {
//     "service" : "ValidateOtp",
//     "data" : {
//       "TrackingId" : username,
//       "MobileNumber" : username,
//       "Source" : "Mobile",
//       "OTP" : otp
//     }
//   };
//    return this.http.post(this.url,JSON.stringify(data));
//  }

 public validateOtp(otp, username): Observable<any>{
  let data = {
    "service" : "ValidateOtp",
    "data" : {
      "username" : username,
      "otp" : otp
    }
  };
  //console.log("username action by ",username);
   return this.http.post(this.url,JSON.stringify(data));
 }

 /*public generateOtp(username): Observable<any>{
      let url = "https://api.indianoil.in/CRMNexPRDExt/OTPAuthentication/OTPAuthenticationRest/SendOTP";
      let data = {
        "TrackingId" : username,
        "MobileNumber" : username,
        "Source" : "Mobile",
        "MappingCode" : "MOBILITY_MSG0001"
      };
      let _httpOptions = this.httpOptions;
      _httpOptions.headers = new HttpHeaders({'Authorization' : btoa('mobility_user:M0b1l1ty@I0cl!')});
      return this.http.post(url,JSON.stringify(data),this.httpOptions);
   }*/

 public generateOtp(username): Observable<any>{
  let data = {
    "username" : username,
  };
  return this.http.post(this.smsUrl,JSON.stringify(data));
}

public saveOtp(savedOTP,username): Observable<any>{
  let data = {
    "service" : "saveOtp",
    "data":{
      "username" : username,
      "otp" : savedOTP,
    }
  };
  return this.http.post(this.smsUrl,JSON.stringify(data));
}

public verifyUsername(username): Observable<any>{
  let data = {
    "service" : "verifyUsername",
    "data":{
      "username" : username
    }
  };
  return this.http.post(this.url,JSON.stringify(data));
}

public uploadNoticeContent(noticeContent,validTill,fileContent,fileName): Observable<any>{
  let data = {
    "service" : "uploadNoticeContent",
    "data":{
      "noticeContent" : noticeContent,
      "validTill" : validTill,
      "fileContent": fileContent,
      "fileName": fileName
    }
  };
  return this.http.post(this.url,JSON.stringify(data));
}

public getNoticeData(){
  let data = {
    "service" : "getNoticeContent",
  }
  return this.http.post(this.url, JSON.stringify(data));
}

public getCBGDataPoints(loiId,usernameout): Observable<any>{
  let data = {
    "service" : "getCBGDataPoints",
    "data":{
      "loiId" : loiId,
      "usernameout" : usernameout
    }
  };
  return this.http.post(this.url,JSON.stringify(data));
}

public updateVendor(UpdatedName, ExistingName): Observable<any>{
  let data = {
    "service" : "updateVendor",
    "data" : {
      "updatedName" : UpdatedName,
      "existingName" : ExistingName
    }
  };
   return this.http.post(this.url,JSON.stringify(data));
 }

 public updateVendorMSTUSER(UpdatedName, ExistingName): Observable<any>{
  let data = {
    "service" : "updateVendorMSTUSER",
    "data" : {
      "updatedName" : UpdatedName,
      "existingName" : ExistingName
    }
  };
   return this.http.post(this.url,JSON.stringify(data));
 }
}