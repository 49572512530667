import { Component, OnInit } from '@angular/core';
import {formatDate} from '@angular/common';
import { HttpService } from 'report/http.service';

@Component({
  selector: 'app-dashboard-detail',
  templateUrl: './dashboard-detail.component.html',
  styleUrls: ['./dashboard-detail.component.css']
})
export class DashboardDetailComponent implements OnInit {

  tableFields;
  detailReport;
  reportKeys;
  toDate = "01-01-2900";
  displaytoDate;
  fromDate = "01-01-1900";
  displayfromDate;
  selectedOmc = "ALL";

  urls = [
    "Satat_Report_PlantComissioned_Detail" 
  ];
  constructor(private httpService : HttpService) { }

  filterChanged(){
    this.toDate = formatDate(this.toDate, 'dd-MM-yyyy', 'en');
    this.displaytoDate = this.toDate;
    this.fromDate = formatDate(this.fromDate, 'dd-MM-yyyy', 'en');
    this.displayfromDate = this.fromDate;
    this.refreshData();
  }

  ngOnInit(): void {
    this.refreshData();
  }

  refreshData(){ 
    this.tableFields = ["OMC", "LOID ID", "VENDOR", "PLANT CAPACITY", "STATE", "DISCTRICT", "COMMISSIONING DATE"];
     this.httpService.getChartData(this.urls[0], this.fromDate, this.toDate, this.selectedOmc).subscribe(res => {
       let resp = res["data"];
       let status = res["status"];
       if(status=="1"){
        this.detailReport = resp["items"];
        if(this.reportKeys)
          this.reportKeys = Object.keys(this.detailReport[0]);
       }   
    });
  }
}