import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pages-scheme',
  templateUrl: './pages-scheme.component.html',
  styleUrls: ['./pages-scheme.component.css']
})
export class PagesSchemeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
