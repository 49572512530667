import {Pipe, PipeTransform} from '@angular/core'; 

@Pipe({
    name: 'filterLoi'
})
export class filterLoi implements PipeTransform{
    transform(items: any[], searchText: string): any[] {
        if (!items) return [];
        if (!searchText) return items;
        //console.log(items);
        return items.filter((item) => 
        {
          //console.log(Object.keys(item));
          //console.log(item);
          return Object.keys(item).some((key) => {
            
          return String(item["loi_reference_number"]).toLowerCase().includes(searchText.toLowerCase());
          });
        });
      }
}