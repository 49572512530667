import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pages-participation',
  templateUrl: './pages-participation.component.html',
  styleUrls: ['./pages-participation.component.css']
})
export class PagesParticipationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
