import { Injectable } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable()
export class LoaderService {

    isLoaded;

    constructor(private spinner: NgxSpinnerService){

    }
    show() {
       
        //document.getElementById('loading').style.visibility = 'visible';
        this.spinner.show();
        //this.isLoaded = false;
    }
    hide() {
            // Setting display of spinner 
            // element to none 
           // this.isLoaded = true;
            //document.getElementById('loading').style.visibility = 'hidden'; 
        this.spinner.hide();
    }


}