import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpService } from '../services/http.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-deleteloi',
  templateUrl: './deleteloi.component.html',
  styleUrls: ['./deleteloi.component.css']
})
export class DeleteloiComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DeleteloiComponent>,
    private httpService :HttpService,
     @Inject(MAT_DIALOG_DATA) public data,
     private _snackBar: MatSnackBar) {
    
        
   }

  ngOnInit(): void {
    console.log("data fg", this.data);
  }

  yes(){
    this.httpService.deleteLoi(0,this.data.loi_system_id).subscribe((resp) =>
        {
            if(resp["status"]==1){
                this.openSnackBar("LOI Status Updated","Ok");
                this.dialogRef.close();
            }

        })

  }

  openSnackBar(message, action) {
    this._snackBar.open(message,action, {
      duration: 5000,
    });
  }


  close(){
    this.dialogRef.close();
  }

}
