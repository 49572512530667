import { Component, OnInit } from '@angular/core';
import { HttpService } from '../services/http.service';
import { HttpClient } from '@angular/common/http';
import { StoreService } from 'app/services/store.service';
import { DomSanitizer, SafeUrl  } from '@angular/platform-browser';
import { timer } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { FormsModule, NgForm } from '@angular/forms';


@Component({
  selector: 'app-commissioned-plants',
  templateUrl: './commissioned-plants.component.html',
  styleUrls: ['./commissioned-plants.component.css']
})


export class CommissionedPlantsComponent implements OnInit {
  sanitizedUrl: any;
  photob641: any;
  plantname: string;
  location: string;
  capacity: string;
  feedstock: string;
  loiissue: string;
  successMsg;
  img_err: string = ""; 
  hasNewFileSelected: boolean = false;
  errorMsg;
  showMsg: boolean= false;
  fileSizeError: string | null = null;

  constructor(
    private httpService :HttpService,
    private store: StoreService,
    private sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<CommissionedPlantsComponent>,
  ) { }

  ngOnInit(): void {
  }


  onFileSelected(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      this.hasNewFileSelected = true;
      reader.readAsDataURL(file);
      reader.onload = () => {       
        this.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + reader.result.toString().split(',')[1]);     
          this.photob641 = reader.result.toString().split(',')[1];
      };
    }
  }

  save(form: NgForm){
    if (!this.photob641 && !this.hasNewFileSelected) {
      this.img_err = "Please Upload image";
      return;
    }
    if (form.valid) {
    this.httpService.uploadCommissionedPlants(this.plantname,this.location,this.capacity,this.feedstock,this.loiissue,this.photob641,this.store.usernameout).subscribe((resp) =>{
      if(resp["status"]==1){
        console.log('Form Valid:', form.valid);
       
          let res = resp["data"];
          console.log("data is ",res);
        
          this.successMsg = res["OUTPUT_MSG"];
          this.errorMsg = res["OUTPUT_MSG"];

          this.showMsg = true;
          timer(1000).subscribe(() => {
            this.showMsg = false;
            this.dialogRef.close();
          });
      }
  });
}
  }

  close(){
    this.dialogRef.close();
  }
}