import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pages-cbg',
  templateUrl: './pages-cbg.component.html',
  styleUrls: ['./pages-cbg.component.css']
})
export class PagesCbgComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
