
export class Approvals {
    public loiId: string;
    public submit: number;
    public actor: number;
    public envClearance: string;
    public pesoApprovalApplied: string;
    public pesoApprovalIssue: string;
    public pesoApprovalIssueDesc: string;
    public consentToOperate: string;
    public consentToOperateIssue: string;
    public consentToOperateIssueDesc: string;
    public consentToEstablish: string;
    public consentToEstablishIssue: string;
    public consentToEstablishIssueDesc: string;
    public operateConsentDate: string;
    public pesoApprovalDate: string;
    public establishConsentDate: string;
    public otherApproval:string;
    constructor(jsonObj=null){
        
        if(jsonObj){
            this.loiId=jsonObj['loi_system_id'];
            this.submit=jsonObj['form_status'];
            this.actor=jsonObj['record_role'];
            this.envClearance=jsonObj['environment_clearance'];
            this.pesoApprovalApplied=jsonObj['peso_approval'];
            this.consentToOperate=jsonObj['pcb_consent_to_operate'];
            this.consentToEstablish=jsonObj['pcb_consent_to_establish'];
            this.operateConsentDate = new Date(jsonObj['pcb_consent_to_operate_date']).toISOString().slice(0,10);
            this.pesoApprovalDate=new Date(jsonObj['peso_approval_date']).toISOString().slice(0,10);
            this.establishConsentDate=new Date(jsonObj['pcb_consent_to_establish_date']).toISOString().slice(0,10);
            this.otherApproval=jsonObj['any_other_approval'];
            this.pesoApprovalIssue = jsonObj['peso_approval_issue'];
            this.pesoApprovalIssueDesc = jsonObj['peso_approval_issue_desc'];
            this.consentToOperateIssue = jsonObj['pcb_consent_to_operate_issue'];
            this.consentToOperateIssueDesc = jsonObj['pcb_consent_operate_desc'];
            this.consentToEstablishIssue = jsonObj['pcb_consent_to_establish_issue'];
            this.consentToEstablishIssueDesc = jsonObj['pcb_consent_establish_desc'];
        } 
    }

  }