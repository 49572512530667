import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { StoreService } from 'app/services/store.service';
import { HttpService } from '../services/http.service';
import { LOV_TYPE } from 'app/shared/Constants';
import { NewGrievance } from './models/grievance';


@Component({
    selector: 'app-grievances',
    templateUrl: './grievances.component.html',
    styleUrls: ['./grievances.component.css'],
    moduleId: module.id
})

export class GrievancesComponent{

    submitted: boolean = false;
    successMsg ;
    errorMsg = "Grievance could not be created. Please try again later or check your input";

    submitBtnText = "Create Grievance";

    loiList;
    selectedloiNo;
    grievanceCategory;
    selectedGrievanceCategory;
    selectedGrievanceSubCategory;
    grievanceSubCategories;
    grievanceCategories;
    grievanceDesc;

    newGrievance : NewGrievance;
    newGrievanceResponseStatus = -1;

    response;

    /*get grievanceCategories(){
        return this.store.grievanceCategories;
    }*/
    

    constructor(
        private httpService: HttpService,
        private store: StoreService,
        private router : Router){
            if(!this.grievanceCategories)
            {       
              this.httpService.fetchLov(LOV_TYPE.GrievanceCatLOV).subscribe((resp)=>{
                if(resp["status"]==1){
                  let res = resp["data"];
                  //this.store.grievanceCategories = res["items"];
                  this.grievanceCategories = res["items"];
                  this.selectedGrievanceCategory = this.grievanceCategories[0];
                  this.fetchGrievanceSubCategories(this.selectedGrievanceCategory.cat_id, true);
                } 
                else if(resp["status"]==403) 
                {
                    this.router.navigate["/"];    
                }
                
                
              });
            }else{
                console.log("List exists");
            }

            this.loiList = this.store.loiList;
            this.selectedloiNo = this.loiList[0];

        }

        ngOnInit(){
            
        }

    fetchGrievanceSubCategories(categoryId, setValue){
     // console.log("hey "+categoryId);
        this.httpService.fetchLov(LOV_TYPE.GrievanceSubCatLOV, categoryId).subscribe((resp)=>{
            if(resp["status"]==1){
              let res = resp["data"];
              //console.log(res);
              this.grievanceSubCategories = res["items"];
               if(setValue) this.selectedGrievanceSubCategory = this.grievanceSubCategories[0];
               
            } else if(resp["status"]==403) this.router.navigate["/"];
          });
    }

    grievanceCategorySelected(){
      //this.grievanceCategory = this.selectedGrievanceCategory.Category;
      this.fetchGrievanceSubCategories(this.selectedGrievanceCategory.cat_id, true);
    }

    onSubmitgrievanceDetails(status){

      let body = {
        CAT_ID: this.selectedGrievanceCategory.cat_id,
        SUB_CAT_ID: this.selectedGrievanceSubCategory.sub_cat_id,
        ISSUE_DESC: this.grievanceDesc,
        USER_ROLE: this.store.actor,
        USER_ID: this.store.userId,
        LOI_REFERENCE_NO: this.selectedloiNo.loi_system_id,
        LOI_REF_NO : this.selectedloiNo.loi_reference_number
      }
      this.httpService.saveGrievance('create-grievance', body).subscribe(
        (resp) => {
          if(resp["status"]==1){
            let res = resp["data"];
            this.newGrievanceResponseStatus = res["STATUS"];
            //console.log("Response : ");
            //console.log(resp);
            this.successMsg = res["OUTPUT_MSG"];
            this.errorMsg = res["OUTPUT_MSG"];
          } else if(resp["status"]==403) this.router.navigate["/"];
        },
        (err) => this.newGrievanceResponseStatus = 0
      );
    }
    
}